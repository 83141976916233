import React, { useContext, useState } from "react";
import styled from "styled-components";
import { Button } from "../../Theme/Hyper";
import Input from "../UI/Form/Input";
import { DataContext } from "../../contexts/DataContext";
const Main = styled.div`
  display: flex;
  flex-direction: column;
`;
const Stats = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid #f2f2f2;

  margin-top: 1em;
  padding-top: 1em;
  max-height: 250px;
`;
const Entry = styled.div`
  display: flex;
  align-items: center;
`;
const List = styled.div`
  display: flex;
  height: 293px;
  overflow-y: auto;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }
  th:nth-child(1) {
    width: 65px;
  }
  th:nth-child(4) {
    width: 100px;
  }
  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
    > td > div {
      color: #ea1047;
    }
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;
export default function Stories({ player, onUpdate }) {
  const [update, setUpdate] = useState(false);
  const [newStory, setNewStory] = useState("");

  if (!player?.stories) {
    player.stories = [];
  }

  let goalStory = player.stories.find((s) => s.type === "goal");
  let yellowStory = player.stories.find((s) => s.type === "yellow");
  let redStory = player.stories.find((s) => s.type === "red");
  return (
    <Main>
      <Input
        title="Goal"
        placeholder="Story when player scores.."
        value={goalStory && goalStory.story}
        onChange={(e) => {
          let index = player.stories.findIndex((s) => s.type === "goal");
          if (index > -1) {
            player.stories[index] = {
              type: "goal",
              number: 1,
              story: e.target.value,
            };
          } else {
            player.stories.push({
              type: "goal",
              number: 1,
              story: e.target.value,
            });
          }
          onUpdate(player);
          setUpdate(!update);
        }}
      />
      <Input
        title="Yellow Card"
        placeholder="Story when yellow card shown.."
        value={yellowStory && yellowStory.story}
        onChange={(e) => {
          let index = player.stories.findIndex((s) => s.type === "yellow");
          if (index > -1) {
            player.stories[index] = {
              type: "yellow",
              number: 1,
              story: e.target.value,
            };
          } else {
            player.stories.push({
              type: "yellow",
              number: 1,
              story: e.target.value,
            });
          }
          onUpdate(player);
          setUpdate(!update);
        }}
      />
      <Input
        title="Red Card"
        placeholder="Story when red card shown.."
        value={redStory && redStory.story}
        onChange={(e) => {
          let index = player.stories.findIndex((s) => s.type === "red");
          if (index > -1) {
            player.stories[index] = {
              type: "red",
              number: 1,
              story: e.target.value,
            };
          } else {
            player.stories.push({
              type: "red",
              number: 1,
              story: e.target.value,
            });
          }
          onUpdate(player);
          setUpdate(!update);
        }}
      />
      <Stats>
        <Entry>
          <Input
            title={"Player Story " + ((newStory && newStory?.length) || 0)}
            placeholder=""
            value={newStory}
            onChange={(e) => {
              // if (e.target.value.length <= 55) {
              setNewStory(e.target.value);
              // }
            }}
            onKeyDown={(e) => {
              if (e.keyCode === 13 && e.target.value !== "") {
                if (!player.stories) {
                  player.stories = [];
                }
                player.stories.push({
                  number:
                    player.stories?.filter((s) => s.type === "stat")?.length +
                    1,
                  story: newStory,
                  type: "stat",
                });
                setNewStory("");
                onUpdate(player);
                setUpdate(!update);
              }
            }}
          />
          {newStory !== "" && (
            <Button
              style={{ marginTop: "18px" }}
              onClick={() => {
                if (!player.stories) {
                  player.stories = [];
                }
                player.stories.push({
                  number:
                    player.stories?.filter((s) => s.type === "stat")?.length +
                    1,
                  story: newStory,
                  line_1: newStory,
                  type: "stat",
                });
                setNewStory("");
                onUpdate(player);
                setUpdate(!update);
              }}
            >
              Add Story
            </Button>
          )}
        </Entry>
        <List>
          <Table>
            <thead>
              <tr>
                <TH>#</TH>
                <TH>Story</TH>
                <TH></TH>
              </tr>
            </thead>
            <tbody>
              {player.stories &&
                player.stories
                  .filter((s) => s.type === "stat")
                  .map((story, index) => {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>
                          <Input
                            title={(story && story.line_1?.length) || 0}
                            value={story.line_1}
                            onChange={(e) => {
                              // if (e.target.value.length <= 55) { REMOVED LIMIT AS SKY DONT NEED IT
                              story.line_1 = e.target.value;
                              onUpdate(player);
                              setUpdate(!update);
                              // }
                            }}
                          />
                        </td>
                        <td>
                          <Input
                            title={(story && story.line_2?.length) || 0}
                            value={story.line_2}
                            onChange={(e) => {
                              // if (e.target.value.length <= 55) { REMOVED LIMIT AS SKY DONT NEED IT
                              story.line_2 = e.target.value;
                              onUpdate(player);
                              setUpdate(!update);
                              // }
                            }}
                          />
                        </td>
                        <td>
                          <Button
                            onClick={() => {
                              player.stories = player.stories.filter(
                                (s) => s !== story
                              );
                              onUpdate(player);
                              setUpdate(!update);
                            }}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
        </List>
      </Stats>
    </Main>
  );
}
