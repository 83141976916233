import React, { useContext, useState } from "react";
import { DataContext } from "../../contexts/DataContext";

import SavePanel from "../UI/Form/SavePanel";
import Modal from "../UI/Modal/Modal";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import CornerBoxEdit from "./CornerBoxEdit";

export default function CornerBoxEditor({
  cornerBox,
  onCancel,
  onSave,
  onUpdate,
  onDelete,
  originalCornerBox,
  game,
}) {
  const { updateCornerBox, deleteCornerBox } = useContext(DataContext);

  return (
    <Modal
      style={{ width: "90%", height: "90vh", marginTop: "0%" }}
      show={cornerBox}
      onCancel={() => {
        onCancel();
      }}
    >
      {cornerBox && (
        <Panel>
          <PanelTitle title={"Corner Box Editor"}></PanelTitle>
          <PanelContent>
            <CornerBoxEdit
              game={game}
              box={{ ...cornerBox }}
              onChange={(box) => {
                onUpdate(box);
              }}
            ></CornerBoxEdit>
          </PanelContent>
          <SavePanel
            showDelete={true}
            style={{ marginLeft: "auto" }}
            onSave={() => {
              updateCornerBox({ data: cornerBox }).then(() => {
                onSave();
              });
            }}
            onCancel={() => {
              onCancel();
            }}
            onDelete={() => {
              deleteCornerBox({ data: cornerBox }).then(() => {
                onDelete();
              });
            }}
          />
        </Panel>
      )}
    </Modal>
  );
}
