import React, { useContext, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import Main from "./components/Views/Main/Main";
import Hyper from "./Theme/Hyper";
import { DataProvider } from "./contexts/DataContext";
import { UIProvider } from "./contexts/UIContext";
import { SocketProvider } from "./contexts/SocketContext";
import Seasons from "./components/Views/Seasons";
import Fixtures from "./components/Views/Fixtures";
import Officials from "./components/Views/Officials";
import Countries from "./components/Views/Countries";
import Stadiums from "./components/Views/Stadiums";
import Competitions from "./components/Views/Competitions";
import Fixture from "./components/Fixture/Fixture";
import Teams from "./components/Views/Teams";
import DataImport from "./components/Views/DataImport";

function App() {
  return (
    <div className="App">
      <Router basename={process.env.PUBLIC_URL}>
        <ThemeProvider theme={Hyper}>
          <UIProvider>
            <DataProvider>
              <SocketProvider>
                <Routes />
              </SocketProvider>
            </DataProvider>
          </UIProvider>
        </ThemeProvider>
      </Router>
    </div>
  );
}

function Routes() {
  return (
    <>
      <Switch>
        <Route exact path="/">
          <Redirect to="/rugby/fixtures" />
        </Route>
        <Route path="/rugby/teams">
          <Main>
            <Teams />
          </Main>
        </Route>
        {/* <Route path="/rugby/players">
            <Main>
              <Players />
            </Main>
          </Route> */}
        <Route path="/rugby/fixtures/:fixtureId">
          <Main>
            <Fixture />
          </Main>
        </Route>
        <Route path="/rugby/fixtures">
          <Main>
            <Fixtures />
          </Main>
        </Route>
        <Route path="/season/:season">
          <Main>
            <Fixtures />
          </Main>
        </Route>
        <Route path="/rugby/competitions">
          <Main>
            <Competitions />
          </Main>
        </Route>
        <Route path="/competition/:comp">
          <Main>
            <Seasons />
          </Main>
        </Route>
        <Route path="/rugby/seasons">
          <Main>
            <Seasons />
          </Main>
        </Route>
        <Route path="/rugby/countries">
          <Main>
            <Countries />
          </Main>
        </Route>
        <Route path="/rugby/officials">
          <Main>
            <Officials />
          </Main>
        </Route>
        <Route path="/rugby/stadiums">
          <Main>
            <Stadiums />
          </Main>
        </Route>
        <Route path="/rugby/import">
          <Main>
            <DataImport />
          </Main>
        </Route>
        <Route path="/">
          <Main />
        </Route>
      </Switch>
    </>
  );
}
export default App;
