import React, { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import { UIContext } from "../../contexts/UIContext";

import PlayerEdit from "../Player/PlayerEdit";
import SavePanel from "../UI/Form/SavePanel";
import Modal from "../UI/Modal/Modal";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import PunditEdit from "../Pundits/PunditEdit";

export default function PunditEditor({
  player,
  selectPlayer,
  onSave,
  onCancel,
}) {
  const { updatePundit, deletePundit } = useContext(DataContext);

  return (
    <Modal
      style={{ width: "70%" }}
      show={player}
      onCancel={() => {
        selectPlayer();
        onCancel();
      }}
    >
      {player && (
        <Panel style={{ height: "90vh" }}>
          <PanelTitle title={"Pundit Edit"}>
            <div>player id: {player._id}</div>
          </PanelTitle>
          <PanelContent>
            <PunditEdit
              player={player}
              onChange={(p) => {
                selectPlayer(p);
              }}
            />
          </PanelContent>

          <SavePanel
            showDelete={true}
            onDelete={() => {
              deletePundit({ punditId: player?._id }).then(() => {
                selectPlayer();
                onSave();
              });
            }}
            onSave={() => {
              let promises = [];

              promises.push(
                updatePundit(player)
                  .then((res) => res.json())
                  .then((new_player) => {})
              );
              Promise.all(promises)
                .then(() => {
                  selectPlayer();
                  onSave();
                })
                .catch(() => {
                  selectPlayer();
                  onSave();
                });
            }}
            onCancel={() => {
              selectPlayer();
              onCancel();
            }}
          ></SavePanel>
        </Panel>
      )}
    </Modal>
  );
}
