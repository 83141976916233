import { getApolloContext } from "@apollo/client";
import React, { useState, useEffect, useContext } from "react";
import gql from "graphql-tag";

const COMPETITIONS = gql`
  query Competitions {
    competitions {
      _id
      name
      description
      opta_ID
      oval_ID
      formation
      code
      country
      country_code
      competition_type
      badge
      secondary_badge
      variables
    }
  }
`;

const ADD_COMPETITION = gql`
  mutation AddCompetition(
    $name: String!
    $order: Int
    $badge: String
    $secondary_badge: String
    $country: String
    $competition_type: String
  ) {
    addCompetition(
      name: $name
      order: $order
      badge: $badge
      secondary_badge: $secondary_badge
      country: $country
      competition_type: $competition_type
    ) {
      _id
    }
  }
`;
const DELETE_COMPETITION = gql`
  mutation DeleteCompetition($id: String!) {
    deleteCompetition(id: $id) {
      _id
    }
  }
`;

const UPDATE_COMPETITION = gql`
  mutation UpdateCompetition(
    $id: String!
    $name: String
    $order: Int
    $badge: String
    $secondary_badge: String
    $country: String
    $competition_type: String
    $variables: JSON
  ) {
    updateCompetition(
      id: $id
      name: $name
      order: $order
      badge: $badge
      secondary_badge: $secondary_badge
      country: $country
      competition_type: $competition_type
      variables: $variables
    ) {
      _id
    }
  }
`;

const ADD_SEASON = gql`
  mutation AddSeason($name: String!, $competition: String!) {
    addSeason(name: $name, competition: $competition) {
      _id
    }
  }
`;
const DELETE_SEASON = gql`
  mutation DeleteSeason($id: String!) {
    deleteSeason(id: $id) {
      _id
    }
  }
`;

const UPDATE_SEASON = gql`
  mutation UpdateSeason($id: String!, $name: String, $competition: String) {
    updateSeason(id: $id, name: $name, competition: $competition) {
      _id
    }
  }
`;

const SEASONS = gql`
  query Seasons {
    seasons {
      _id
      name
      competition {
        _id
        name
        badge
        competition_type
        variables
      }
      teams {
        _id
        name
        stadium {
          _id
          opta_name
          name
          location
        }
      }
    }
  }
`;

const TEAMS = gql`
  query Teams {
    teams {
      _id
      name
      code
      short_name
      club_name
      type
      badge_name
      country
      status
      post_code
      address
      founded
      variables
      rugby_league_ID
      variables
    }
  }
`;

const TEAMS_BY_SEASON = gql`
  query TeamsBySeason($id: String!) {
    teamsBySeason(id: $id) {
      _id
      name
      code
      short_name
      club_name
      type
      badge_name
      country
      status
      post_code
      address
      founded
      variables
      rugby_league_ID
      variables
    }
  }
`;

const ADD_TEAM = gql`
  mutation AddTeam(
    $name: String
    $short_name: String
    $club_name: String
    $code: String
    $variables: JSON
    $rugby_league_ID: String
  ) {
    addTeam(
      name: $name
      short_name: $short_name
      club_name: $club_name
      code: $code
      variables: $variables
      rugby_league_ID: $rugby_league_ID
    ) {
      _id
    }
  }
`;

const ADD_TEAM_TO_SEASON = gql`
  mutation AddTeamToSeason($season: String!, $team: String!) {
    addTeamToSeason(season: $season, team: $team) {
      _id
    }
  }
`;

const UPDATE_TEAM = gql`
  mutation UpdateTeam(
    $id: String!
    $name: String
    $short_name: String
    $club_name: String
    $code: String
    $stadium: String
    $variables: JSON
    $rugby_league_ID: String
  ) {
    updateTeam(
      id: $id
      name: $name
      short_name: $short_name
      club_name: $club_name
      code: $code
      stadium: $stadium
      variables: $variables
      rugby_league_ID: $rugby_league_ID
    ) {
      _id
    }
  }
`;

const ADD_PUNDIT = gql`
  mutation AddPundit(
    $first_name: String
    $first_name_upper: String
    $last_name: String
    $last_name_upper: String
    $group: String
    $stories: [JSON]
    $ss_logo: Boolean
    $badge: String
  ) {
    addPundit(
      first_name: $first_name
      first_name_upper: $first_name_upper
      last_name: $last_name
      last_name_upper: $last_name_upper
      group: $group
      stories: $stories
      ss_logo: $ss_logo
      badge: $badge
    ) {
      _id
    }
  }
`;

const ADD_PLAYER = gql`
  mutation AddPlayer(
    $first_name: String
    $first_name_upper: String
    $last_name: String
    $last_name_upper: String
    $short_name: String
    $short_name_upper: String
    $nationality: String
    $title: String
    $image: String
    $stories: [JSON]
  ) {
    addPlayer(
      first_name: $first_name
      first_name_upper: $first_name_upper
      last_name: $last_name
      last_name_upper: $last_name_upper
      short_name: $short_name
      short_name_upper: $short_name_upper
      nationality: $nationality
      image: $image
      title: $title
      stories: $stories
    ) {
      _id
    }
  }
`;

const ADD_PLAYER_TO_SQUAD = gql`
  mutation AddPlayerToSquad(
    $player: String!
    $season: String!
    $team: String!
    $type: String!
    $shirt_number: Int
    $position: String
  ) {
    addPlayerToSquad(
      player: $player
      season: $season
      team: $team
      type: $type
      shirt_number: $shirt_number
      position: $position
    ) {
      _id
    }
  }
`;

const UPDATE_SQUAD_PLAYER = gql`
  mutation UpdateSquadPlayer(
    $id: String!
    $shirt_number: Int
    $position: String
    $active: Boolean
  ) {
    updateSquadPlayer(
      id: $id
      shirt_number: $shirt_number
      position: $position
      active: $active
    ) {
      _id
    }
  }
`;

const UPDATE_PUNDIT = gql`
  mutation UpdatePundit(
    $id: String!
    $first_name: String
    $first_name_upper: String
    $last_name: String
    $last_name_upper: String
    $group: String
    $ss_logo: Boolean
    $stories: [JSON]
    $badge: String
  ) {
    updatePundit(
      id: $id
      first_name: $first_name
      first_name_upper: $first_name_upper
      last_name: $last_name
      last_name_upper: $last_name_upper
      group: $group
      stories: $stories
      ss_logo: $ss_logo
      badge: $badge
    ) {
      _id
    }
  }
`;

const UPDATE_PLAYER = gql`
  mutation UpdatePlayer(
    $id: String!
    $first_name: String
    $first_name_upper: String
    $last_name: String
    $last_name_upper: String
    $short_name: String
    $short_name_upper: String
    $nationality: String
    $title: String
    $image: String
    $stories: [JSON]
  ) {
    updatePlayer(
      id: $id
      first_name: $first_name
      first_name_upper: $first_name_upper
      last_name: $last_name
      last_name_upper: $last_name_upper
      short_name: $short_name
      short_name_upper: $short_name_upper
      nationality: $nationality
      title: $title
      image: $image
      stories: $stories
    ) {
      _id
    }
  }
`;

const DELETE_PLAYER = gql`
  mutation DeletePlayer($id: String!) {
    deletePlayer(id: $id) {
      _id
    }
  }
`;
const DELETE_PUNDIT = gql`
  mutation DeletePundit($id: String!) {
    deletePundit(id: $id) {
      _id
    }
  }
`;
const NATIONS = gql`
  query Nations {
    nations {
      _id
      name
      code
      opta_ID
      opta_code
      opta_name
    }
  }
`;

const PUNDITS = gql`
  query Pundits {
    pundits {
      _id
      first_name
      last_name
      first_name_upper
      last_name_upper
      group
      stories
      ss_logo
      badge
    }
  }
`;

const PLAYERS = gql`
  query Players {
    players {
      _id
      first_name
      last_name
      short_name
      short_name_upper
    }
  }
`;

const REMOVE_PLAYER_FROM_SQUAD = gql`
  mutation RemovePlayerFromSquad($id: String!) {
    removePlayerFromSquad(id: $id) {
      _id
    }
  }
`;

const SQUAD_BY_TEAM = gql`
  query Squad($teamId: String, $seasonId: String) {
    squadByTeam(teamId: $teamId, seasonId: $seasonId) {
      _id
      active
      type
      shirt_number
      opta_shirt_number
      opta_position
      opta_active
      position
      stats
      stories
      player {
        _id
        opta_ID
        first_name
        last_name
        first_name_upper
        last_name_upper
        short_name
        short_name_upper
        opta_first_name
        opta_last_name

        nationality {
          _id
          opta_code
          opta_name
        }
        image
        title
        stories
      }
      team {
        _id
        name
        short_name
        code
      }
    }
  }
`;

const SQUAD_BY_SEASON = gql`
  query Squad($seasonId: String) {
    squadBySeason(seasonId: $seasonId) {
      _id
      active
      type
      shirt_number
      opta_shirt_number
      opta_position
      opta_active
      position
      stats
      stories
      player {
        _id
        opta_ID
        first_name
        last_name
        first_name_upper
        last_name_upper
        short_name
        short_name_upper
        opta_first_name
        opta_last_name

        nationality {
          _id
          opta_code
          opta_name
        }
        image
        title
        stories
      }
      team {
        _id
        name
        short_name
        code
        variables
      }
    }
  }
`;
const GAMES_BY_DATE = gql`
  query Games($date: String) {
    gamesByDate(date: $date) {
      _id
      time
      description
      location
      date
      stadium {
        opta_name
        name
        location
      }
      competition {
        name
        _id
        badge
        competition_type
        variables
      }
      opta_lineup {
        home {
          squad {
            _id
            opta_shirt_number
            opta_position
            stories
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper
              short_name
              short_name_upper
              nationality {
                _id
                opta_code
                opta_name
              }
              image
            }
          }
          order
        }
        away {
          squad {
            _id
            opta_shirt_number
            opta_position
            stories
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper
              short_name
              short_name_upper
              nationality {
                _id
                opta_code
                opta_name
              }
              image
            }
          }
          order
        }
      }
      lineup {
        home {
          squad {
            _id
            opta_shirt_number
            opta_position
            stats
            stories
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper
              short_name
              short_name_upper
              nationality {
                _id
                opta_code
                opta_name
              }
              image
              stories
            }
          }
          order
          subbed
          sub_order
          captain
        }
        away {
          squad {
            _id
            opta_shirt_number
            opta_position
            stats
            stories
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper
              short_name
              short_name_upper
              nationality {
                _id
                opta_code
                opta_name
              }
              image
              stories
            }
          }
          order
          subbed
          sub_order
          captain
        }
      }
      home_team {
        name
        code
      }
      away_team {
        name
        code
      }
      live_data
    }
  }
`;

const GAMES_BY_MONTH = gql`
  query Games($date: String) {
    gamesByMonth(date: $date) {
      _id
    }
  }
`;

const GAME_BY_ID = gql`
  query Game($id: String) {
    gameById(id: $id) {
      _id
      time
      description
      location
      date
      home_interchanges_total
      home_interchanges_total_gp
      away_interchanges_total
      away_interchanges_total_gp
      commentators {
        first_name
        last_name
      }
      stadium {
        opta_name
        name
        location
      }
      officials {
        official {
          first_name
          last_name
        }
        type
      }
      competition {
        name
        _id
        badge
        competition_type
        variables
      }
      lineup {
        home {
          squad {
            _id
            opta_shirt_number
            opta_position
            stats
            stories
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper
              short_name
              short_name_upper
              image
              stories
              nationality {
                _id
                opta_code
                opta_name
              }
            }
          }
          order
        }
        away {
          squad {
            _id
            opta_shirt_number
            opta_position
            stats
            stories
            player {
              _id
              first_name
              last_name
              image
              first_name_upper
              last_name_upper
              short_name
              short_name_upper
              stories
              nationality {
                _id
                opta_code
                opta_name
              }
            }
          }
          order
        }
      }
      opta_lineup {
        home {
          squad {
            _id
            opta_shirt_number
            opta_position
            stories
            player {
              _id
              first_name
              last_name
              image
              first_name_upper
              last_name_upper
              short_name
              short_name_upper
              nationality {
                _id
                opta_code
                opta_name
              }
            }
          }
          order
        }
        away {
          squad {
            _id
            opta_shirt_number
            opta_position
            stories
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper
              short_name
              short_name_upper
              nationality {
                _id
                opta_code
                opta_name
              }
              image
            }
          }
          order
        }
      }
      home_team {
        _id
        name
        code
      }
      away_team {
        _id
        name
        code
      }
      live_data
      season {
        _id
      }
    }
  }
`;

const ADD_GAME = gql`
  mutation AddGame(
    $competition: String!
    $season: String!
    $home_team: String!
    $away_team: String!
    $date: String!
    $time: String!
    $stadium: String!
    $description: String
  ) {
    addGame(
      competition: $competition
      season: $season
      home_team: $home_team
      away_team: $away_team
      date: $date
      time: $time
      stadium: $stadium
      description: $description
    ) {
      _id
    }
  }
`;

const STADIUM_BY_ID = gql`
  query Stadium($id: String) {
    stadiumById(id: $id) {
      _id
      name
      opta_name
      opta_ID
      oval_ID
      location
    }
  }
`;

const STADIUMS = gql`
  query Stadiums {
    stadiums {
      _id
      name
      opta_name
      opta_ID
      oval_ID
      location
    }
  }
`;

const OFFICIALS = gql`
  query Officials {
    officials {
      _id
      first_name
      last_name
      opta_ID
    }
  }
`;

const UPDATE_GAME = gql`
  mutation UpdateGame($id: String!, $live_data: JSON) {
    updateGame(id: $id, live_data: $live_data) {
      live_data
    }
  }
`;

const UPDATE_GAME_LOCATION = gql`
  mutation UpdateGame($id: String!, $location: String) {
    updateGame(id: $id, location: $location) {
      live_data
    }
  }
`;

const ADD_STADIUM = gql`
  mutation addStadium($name: String,$location: String) {
    addStadium(name: $name,location: $location) {
      _id
    }
  }
`;

const UPDATE_STADIUM = gql`
  mutation UpdateStadium($id: String!, $name: String,$location: String) {
    updateStadium(id: $id, name: $name,location: $location) {
      _id
    }
  }
`;

const ADD_COUNTRY = gql`
  mutation addCountry($name: String, $code: String) {
    addCountry(name: $name, code: $code) {
      _id
    }
  }
`;

const UPDATE_COUNTRY = gql`
  mutation UpdateCountry($id: String!, $name: String, $code: String) {
    updateCountry(id: $id, name: $name, code: $code) {
      _id
    }
  }
`;

const UPDATE_LINEUP = gql`
  mutation UpdateLineup($id: String!, $lineup: JSON) {
    updateGame(id: $id, lineup: $lineup) {
      _id
    }
  }
`;

const UPDATE_HOME_KIT = gql`
  mutation UpdateKit($id: String!, $kit: String!) {
    updateGame(id: $id, home_kit: $kit) {
      home_team_kit
    }
  }
`;

const UPDATE_AWAY_KIT = gql`
  mutation UpdateKit($id: String!, $kit: String!) {
    updateGame(id: $id, away_kit: $kit) {
      away_team_kit
    }
  }
`;

const UPDATE_PUNDITS = gql`
  mutation UpdatePundits($id: String!, $pundits: JSON) {
    updateGame(id: $id, pundits: $pundits) {
      _id
    }
  }
`;

const UPDATE_HOME_INTERCHANGES = gql`
  mutation UpdateInterchanges(
    $id: String!
    $interchanges: Int
    $interchanges_gp: Int
  ) {
    updateGame(
      id: $id
      home_interchanges_total: $interchanges
      home_interchanges_total_gp: $interchanges_gp
    ) {
      home_interchanges_total
      home_interchanges_total_gp
    }
  }
`;

const UPDATE_AWAY_INTERCHANGES = gql`
  mutation UpdateInterchanges(
    $id: String!
    $interchanges: Int
    $interchanges_gp: Int
  ) {
    updateGame(
      id: $id
      away_interchanges_total: $interchanges
      away_interchanges_total_gp: $interchanges_gp
    ) {
      away_interchanges_total
      away_interchanges_total_gp
    }
  }
`;

const UPDATE_COMMENTATORS = gql`
  mutation UpdateCommentators($id: String!, $commentators: JSON) {
    updateGame(id: $id, commentators: $commentators) {
      _id
    }
  }
`;

const UPDATE_SEASON_STATS = gql`
  mutation UpdateSeasonStats($id: String!, $stats: JSON, $stories: [JSON]) {
    updateSeasonStats(id: $id, stats: $stats, stories: $stories) {
      _id
    }
  }
`;

const SEASON_STATS = gql`
  query SeasonStats($id: String!) {
    seasonStatRankingsBySeason(seasonId: $id) {
      _id
      opta_player_stats {
        type
        players {
          value
          rank
          squad {
            player {
              first_name
              last_name
            }
          }
          team {
            name
            code
          }
        }
      }
    }
  }
`;

const CORNER_BOXES = gql`
  query CornerBoxes {
    cornerboxes {
      _id
      number
      name
      type
      number_rows
      number_columns
      text_type
      title_badge
      heading
      subtitle
      column_1
      column_2
      column_3
      column_4
      rows
      show_badges
      title_badge_folder
      game {
        _id
      }
    }
  }
`;

const UPDATE_CORNERBOX = gql`
  mutation UpdateCornerBox(
    $id: String!
    $number: Int
    $name: String
    $type: String
    $rows: [JSON]
    $number_rows: Int
    $number_columns: Int
    $text_type: String
    $title_badge: String
    $heading: String
    $subtitle: String
    $column_1: String
    $column_2: String
    $column_3: String
    $column_4: String
    $show_badges: Boolean
    $game: String
    $title_badge_folder: String
  ) {
    updateCornerBox(
      id: $id
      number: $number
      name: $name
      type: $type
      rows: $rows
      number_rows: $number_rows
      number_columns: $number_columns
      text_type: $text_type
      title_badge: $title_badge
      heading: $heading
      subtitle: $subtitle
      column_1: $column_1
      column_2: $column_2
      column_3: $column_3
      column_4: $column_4
      show_badges: $show_badges
      game: $game
      title_badge_folder: $title_badge_folder
    ) {
      _id
    }
  }
`;

const ADD_CORNERBOX = gql`
  mutation AddCornerBox(
    $name: String
    $number: Int
    $type: String
    $rows: [JSON]
    $number_rows: Int
    $number_columns: Int
    $text_type: String
    $title_badge: String
    $heading: String
    $subtitle: String
    $column_1: String
    $column_2: String
    $column_3: String
    $column_4: String
    $show_badges: Boolean
    $game: String
    $title_badge_folder: String
  ) {
    addCornerBox(
      name: $name
      number: $number
      type: $type
      rows: $rows
      number_rows: $number_rows
      number_columns: $number_columns
      text_type: $text_type
      title_badge: $title_badge
      heading: $heading
      subtitle: $subtitle
      column_1: $column_1
      column_2: $column_2
      column_3: $column_3
      column_4: $column_4
      show_badges: $show_badges
      game: $game
      title_badge_folder: $title_badge_folder
    ) {
      _id
    }
  }
`;
const DELETE_CORNERBOX = gql`
  mutation DeleteCornerBox($id: String) {
    deleteCornerBox(id: $id) {
      _id
    }
  }
`;

const DataContext = React.createContext([{}, () => {}]);
const server = window.location.protocol + "//" + window.SERVER + ":8082";
window.DATA_SERVER = server;
const DataProvider = (props) => {
  const { client } = useContext(getApolloContext());

  function getSecondSpectrumAveragePositions({ gameId }) {
    return fetch(server + "/hyper/v1/second_spectrum/shape_summary/" + gameId)
      .then((response) => response.text())
      .then((data) => {
        let arr = data.split(/\r?\n/);
        return arr.map((item) => JSON.parse(item));
      });
  }

  function getCornerBoxes() {
    return client
      .query({
        query: CORNER_BOXES,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.cornerboxes;
      })
      .catch((err) => console.error(err));
  }

  function updateCornerBox({ data }) {
    if (data._id) {
      return client
        .mutate({
          mutation: UPDATE_CORNERBOX,
          variables: {
            number: parseInt(data.number),
            id: data._id,
            name: data.name,
            type: data.type,
            number_rows: data.number_rows,
            number_columns: data.number_columns,
            text_type: data.text_type,
            title_badge: data.title_badge,
            heading: data.heading,
            subtitle: data.subtitle,
            column_1: data.column_1,
            column_2: data.column_2,
            column_3: data.column_3,
            column_4: data.column_4,
            rows: data.rows,
            show_badges: data.show_badges,
            game: data.game?._id,
            title_badge_folder: data?.title_badge_folder,
          },
        })
        .then((response) => {
          return response.data.updateCornerBox;
        })
        .catch((err) => console.error(err));
    } else {
      return client
        .mutate({
          mutation: ADD_CORNERBOX,
          variables: {
            number: parseInt(data.number),
            name: data.name,
            type: data.type,
            number_rows: data.number_rows,
            number_columns: data.number_columns,
            text_type: data.text_type,
            title_badge: data.title_badge,
            heading: data.heading,
            subtitle: data.subtitle,
            column_1: data.column_1,
            column_2: data.column_2,
            column_3: data.column_3,
            column_4: data.column_4,
            rows: data.rows,
            show_badges: data.show_badges,
            game: data.game,
            title_badge_folder: data?.title_badge_folder,
          },
        })
        .then((response) => {
          return response.data.addCornerBox;
        })
        .catch((err) => console.error(err));
    }
  }

  function deleteCornerBox({ data }) {
    if (data._id) {
      return client
        .mutate({
          mutation: DELETE_CORNERBOX,
          variables: {
            id: data._id,
          },
        })
        .then((response) => {
          return response.data.deleteCornerBox;
        })
        .catch((err) => console.error(err));
    }
  }

  function getSeason(seasonId) {
    return client
      .query({
        query: SEASONS,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.seasons;
      })
      .catch((err) => console.error(err));
  }
  function getOfficial(officialId) {
    if (officialId) {
    } else {
      return client
        .query({
          query: OFFICIALS,
        })
        .then((response) => {
          return response.data.officials;
        })
        .catch((err) => console.error(err));
    }
  }
  function getStadium(stadiumId) {
    if (stadiumId) {
      return client
        .query({
          query: STADIUM_BY_ID,
          fetchPolicy: "network-only",
          variables: {
            id: stadiumId,
          },
        })
        .then((response) => {
          return response.data.stadiumById;
        })
        .catch((err) => console.error(err));
    } else {
      return client
        .query({
          query: STADIUMS,
          fetchPolicy: "network-only",
        })
        .then((response) => {
          return response.data.stadiums;
        })
        .catch((err) => console.error(err));
    }
  }

  function deleteCompetition({ data }) {
    return client
      .mutate({
        mutation: DELETE_COMPETITION,
        variables: {
          id: data._id,
        },
      })
      .then((response) => {
        return response.data.deleteCompetition;
      })
      .catch((err) => console.error(err));
  }

  function getCompetition(compId) {
    return client
      .query({
        query: COMPETITIONS,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.competitions;
      })
      .catch((err) => console.error(err));
  }

  function updateCompetition({ data }) {
    if (data._id) {
      return client
        .mutate({
          mutation: UPDATE_COMPETITION,
          variables: {
            id: data._id,
            name: data.name,
            order: parseInt(data.order),
            badge: data.badge,
            secondary_badge: data.secondary_badge,
            country: data.country,
            competition_type: data.competition_type,
            variables: data.variables,
          },
        })
        .then((response) => {
          return response.data.updateCompetition;
        })
        .catch((err) => console.error(err));
    } else {
      return client
        .mutate({
          mutation: ADD_COMPETITION,
          variables: {
            name: data.name,
            order: parseInt(data.order),
            badge: data.badge,
            country: data.country,
            competition_type: data.competition_type,
          },
        })
        .then((response) => {
          return response.data.addCompetition;
        })
        .catch((err) => console.error(err));
    }
  }

  function deleteSeason({ data }) {
    return client
      .mutate({
        mutation: DELETE_SEASON,
        variables: {
          id: data._id,
        },
      })
      .then((response) => {
        return response.data.deleteSeason;
      })
      .catch((err) => console.error(err));
  }

  function updateSeason({ data }) {
    if (data._id) {
      return client
        .mutate({
          mutation: UPDATE_SEASON,
          variables: {
            id: data._id,
            name: data.name,
            competition: data.competition,
          },
        })
        .then((response) => {
          return response.data.updateSeason;
        })
        .catch((err) => console.error(err));
    } else {
      return client
        .mutate({
          mutation: ADD_SEASON,
          variables: {
            id: data._id,
            name: data.name,
            competition: data.competition,
          },
        })
        .then((response) => {
          return response.data.addSeason;
        })
        .catch((err) => console.error(err));
    }
  }

  function updatePundit(data) {
    if (data._id) {
      return client
        .mutate({
          mutation: UPDATE_PUNDIT,
          variables: {
            id: data._id,
            first_name: data.first_name,
            first_name_upper: data.first_name_upper,
            last_name: data.last_name,
            last_name_upper: data.last_name_upper,
            group: data.group,
            stories: data.stories,
            ss_logo: data.ss_logo,
            badge: data.badge,
          },
        })
        .then((response) => {
          return response.data.updatePundit;
        })
        .catch((err) => console.error(err));
    } else {
      return client
        .mutate({
          mutation: ADD_PUNDIT,
          variables: {
            first_name: data.first_name,
            first_name_upper: data.first_name_upper,
            last_name: data.last_name,
            last_name_upper: data.last_name_upper,
            group: data.group,
            stories: data.stories,
            ss_logo: data.ss_logo,
            badge: data.badge,
          },
        })
        .then((response) => {
          return response.data.addPundit;
        })
        .catch((err) => console.error(err));
    }
  }

  function updatePlayer(data) {
    if (data._id) {
      return client
        .mutate({
          mutation: UPDATE_PLAYER,
          variables: {
            id: data._id,
            first_name: data.first_name,
            first_name_upper: data.first_name_upper,
            last_name: data.last_name,
            last_name_upper: data.last_name_upper,
            short_name: data.short_name,
            short_name_upper: data.short_name_upper,
            nationality: data.nationality?._id,
            image: data.image,
            title: data.title,
            stories: data.stories,
          },
        })
        .then((response) => {
          return response.data.updatePlayer;
        })
        .catch((err) => console.error(err));
    } else {
      return client
        .mutate({
          mutation: ADD_PLAYER,
          variables: {
            first_name: data.first_name,
            first_name_upper: data.first_name_upper,
            last_name: data.last_name,
            last_name_upper: data.last_name_upper,
            short_name: data.short_name,
            short_name_upper: data.short_name_upper,
            nationality: data.nationality?._id,
            image: data.image,
            title: data.title,
            stories: data.stories,
          },
        })
        .then((response) => {
          return response.data.addPlayer;
        })
        .catch((err) => console.error(err));
    }
  }

  function deletePlayer({ playerId }) {
    return client
      .mutate({
        mutation: DELETE_PLAYER,
        variables: {
          id: playerId,
        },
      })
      .then((response) => {
        return response.data.deletePlayer;
      })
      .catch((err) => console.error(err));
  }

  function deletePundit({ punditId }) {
    return client
      .mutate({
        mutation: DELETE_PUNDIT,
        variables: {
          id: punditId,
        },
      })
      .then((response) => {
        return response.data.deletePundit;
      })
      .catch((err) => console.error(err));
  }

  function updateSquadPlayer(player) {
    return client
      .mutate({
        mutation: UPDATE_SQUAD_PLAYER,
        variables: {
          id: player._id,
          shirt_number: parseInt(player.shirt_number),
          position: player.position,
          active: player.active,
        },
      })
      .then((response) => {
        return response.data.deletePlayer;
      })
      .catch((err) => console.error(err));
  }

  function getSquads() {
    return fetch(server + "/squads/")
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function addToSquad({
    playerId,
    teamId,
    seasonId,
    shirt_number,
    position,
    type = "player",
  }) {
    if (playerId) {
      return client
        .mutate({
          mutation: ADD_PLAYER_TO_SQUAD,
          variables: {
            player: playerId,
            team: teamId,
            season: seasonId,
            shirt_number: parseInt(shirt_number),
            position,
            type,
          },
        })
        .then((response) => {
          return response.data.addPlayerToSquad;
        })
        .catch((err) => console.error(err));
    }
  }

  function getTeams() {
    return client
      .query({
        query: TEAMS,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.teams;
      })
      .catch((err) => console.error(err));
  }
  function getTeamsBySeason({ seasonId }) {
    return client
      .query({
        query: TEAMS_BY_SEASON,
        fetchPolicy: "network-only",
        variables: {
          id: seasonId,
        },
      })
      .then((response) => {
        return response.data.teamsBySeason;
      })
      .catch((err) => console.error(err));
  }
  function updateTeam(team) {
    return client
      .mutate({
        mutation: UPDATE_TEAM,
        variables: {
          id: team._id,
          name: team.name,
          short_name: team.short_name,
          club_name: team.club_name,
          code: team.code,
          variables: team.variables,
          stadium: team.stadium?._id,
          rugby_league_ID: team.rugby_league_ID,
        },
      })
      .then((response) => {
        return response.data.updateTeam;
      })
      .catch((err) => console.error(err));
  }

  function getFixture({ fixtureId }) {
    return client
      .query({
        query: GAME_BY_ID,
        fetchPolicy: "network-only",
        variables: {
          id: fixtureId,
        },
      })
      .then((response) => {
        return response.data.gameById;
      })
      .catch((err) => console.error(err));
  }

  function getCountry({ countryId }) {
    return client
      .query({
        query: NATIONS,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.nations;
      })
      .catch((err) => console.error(err));
  }

  function getPundits() {
    return client
      .query({
        query: PUNDITS,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.pundits;
      })
      .catch((err) => console.error(err));
  }

  function getPlayers() {
    return client
      .query({
        query: PLAYERS,
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.players;
      })
      .catch((err) => console.error(err));
  }

  function getSquadPlayers({ teamId, seasonId, coach }) {
    return client
      .query({
        query: SQUAD_BY_TEAM,
        fetchPolicy: "network-only",
        variables: {
          teamId: teamId,
          seasonId: seasonId,
        },
      })
      .then((response) => {
        return response.data.squadByTeam;
      })
      .catch((err) => console.error(err));
  }

  function getSquadBySeason({ seasonId }) {
    return client
      .query({
        query: SQUAD_BY_SEASON,
        fetchPolicy: "network-only",
        variables: {
          seasonId: seasonId,
        },
      })
      .then((response) => {
        return response.data.squadBySeason;
      })
      .catch((err) => console.error(err));
  }

  function getCoach({ teamId, seasonId }) {
    return getCountry({}).then((countries) => {
      return getSquadPlayers({
        teamId: teamId,
        seasonId: seasonId,
        coach: true,
      }).then((coach) => {
        if (coach.length > 0) {
          return fetch(server + "/players/" + coach[0].player)
            .then((response) => response.json())
            .then((data) => {
              return {
                ...coach.find((s) => s.player === data._id),
                ...data,
                nationality: countries.find((c) => c._id === data.nationality),
              };
            });
        }
      });
    });
  }

  function getSquad({ teamId, seasonId }) {
    return getSquadPlayers({ teamId: teamId, seasonId: seasonId });
  }

  function getLineup({ fixtureId, team }) {
    return getCountry({}).then((countries) => {
      return getFixture({ fixtureId: fixtureId }).then((fixture) => {
        let team_lineup = fixture.live_data[`${team}_team_lineup`];
        let url = "";
        return getSquadPlayers({
          teamId: fixture[team + "_team"],
          seasonId: fixture.season,
        }).then((squadPlayers) => {
          if (team_lineup && team_lineup.length > 1) {
            url =
              server +
              "/players/?$orderby=last_name&$filter=_id $in " +
              team_lineup.map((d) => d.player).join(",");
          } else if (team_lineup && team_lineup.length > 0) {
            url = server + "/players/" + team_lineup[0].player;
          }

          if (url === "") {
            return Promise.resolve();
          }

          return fetch(url)
            .then((response) => response.json())
            .then((data) => {
              if (Array.isArray(data)) {
                return team_lineup.map((l) => {
                  let player = data.find((p) => p._id === l.player);
                  return {
                    ...l,
                    ...squadPlayers.find((p) => p.player === l.player),
                    ...player,
                    nationality: countries.find(
                      (c) => c._id === player.nationality
                    ),
                  };
                });
              } else {
                return team_lineup.map((l) => {
                  return {
                    ...l,
                    ...squadPlayers.find((p) => p.player === l.player),
                    ...data,
                    nationality: countries.find(
                      (c) => c._id === data.nationality
                    ),
                  };
                });
              }
            });
        });
      });
    });
  }

  function getPlayersBySearch({ search, count, limit, page }) {
    let url = server + "/players?$filter=last_name $regex '^" + search + "'";
    if (count) {
      url += "&$count";
    }
    if (limit) {
      url += "&$limit=" + limit;
    }
    if (page) {
      url += "&$skip=" + page;
    }

    return fetch(url)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function getPlayer({ playerId }) {
    return fetch(server + "/players/" + (playerId || ""))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function createFixture({ fixture }) {
    return client
      .mutate({
        mutation: ADD_GAME,
        variables: {
          competition: fixture.competition,
          season: fixture.season,
          home_team: fixture.home_team,
          away_team: fixture.away_team,
          date: fixture.date,
          time: fixture.time,
          stadium: fixture.stadium,
          description: fixture.description,
        },
      })
      .then((response) => {
        return response.data.addGame;
      })
      .catch((err) => console.error(err));
  }

  function deleteFixture({ fixtureId }) {
    return fetch(server + "/games/" + fixtureId, {
      method: "DELETE",
    });
  }

  function deleteSquadPlayer({ playerId }) {
    return client
      .mutate({
        mutation: REMOVE_PLAYER_FROM_SQUAD,
        variables: {
          id: playerId,
        },
      })
      .then((response) => {
        return response.data.removePlayerFromSquad;
      })
      .catch((err) => console.error(err));
  }

  function updateOfficial({ data }) {
    return fetch(server + "/officials/" + data._id, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  function updateGame({ id, live_data }) {
    return client
      .mutate({
        mutation: UPDATE_GAME,
        variables: { id, live_data },
      })
      .then((response) => {
        return response.data.updateGame;
      })
      .catch((err) => console.error(err));
  }

  function updateGameLocation({ id, location }) {
    return client
      .mutate({
        mutation: UPDATE_GAME_LOCATION,
        variables: { id, location },
      })
      .then((response) => {
        return response.data.updateGame;
      })
      .catch((err) => console.error(err));
  }

  function updateKit({ id, kit, team }) {
    return client
      .mutate({
        mutation: team === "home" ? UPDATE_HOME_KIT : UPDATE_AWAY_KIT,
        variables: { id, kit },
      })
      .then((response) => {
        return response.data.updateGame;
      })
      .catch((err) => console.error(err));
  }

  function updateInterchanges({ id, interchanges, interchanges_gp, team }) {
    return client
      .mutate({
        mutation:
          team === "home" ? UPDATE_HOME_INTERCHANGES : UPDATE_AWAY_INTERCHANGES,
        variables: { id, interchanges, interchanges_gp },
      })
      .then((response) => {
        return response.data.updateGame;
      })
      .catch((err) => console.error(err));
  }

  function updateGamePundits({ id, pundits }) {
    return client
      .mutate({
        mutation: UPDATE_PUNDITS,
        variables: { id, pundits },
      })
      .then((response) => {
        return response.data.updateGame;
      })
      .catch((err) => console.error(err));
  }

  function updateLineup({ id, lineup }) {
    return client
      .mutate({
        mutation: UPDATE_LINEUP,
        variables: { id, lineup },
      })
      .then((response) => {
        return response.data.updateLineup;
      })
      .catch((err) => console.error(err));
  }

  function updateGameOfficial({ fixtureId, official, type }) {
    return fetch(server + "/games/" + fixtureId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        $set: { [type]: official._id },
      }),
    });
  }

  function updateGameCommentator({ fixtureId, commentator }) {
    return fetch(server + "/games/" + fixtureId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        $set: { "variables.commentator": commentator },
      }),
    });
  }

  function updateGameSecondSpectrum({ fixtureId, secondSpectrumID }) {
    return fetch(server + "/games/" + fixtureId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        $set: { "variables.second_spectrum_id": secondSpectrumID },
      }),
    });
  }

  function updateHeadCoach({ teamId, coach }) {
    return fetch(server + "/teams/" + teamId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        $set: { coach: coach },
      }),
    });
  }

  function getTransfer({ transferId }) {
    return fetch(server + "/transfers/" + (transferId || ""))
      .then((response) => response.json())
      .then(async (data) => {
        for (let i = 0; i < data.length; i++) {
          data[i] = {
            ...data[i],
            player: await getPlayer({ playerId: data[i].player }),
          };
        }
        return data;
      });
  }

  function getFormation({ formation }) {
    return new Promise((resolve) => resolve);
  }

  function updateFormation({ data, gameId }) {
    if (data._id) {
      return fetch(server + "/formations/" + data._id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    } else {
      return fetch(server + "/formations/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    }
  }

  function updateGameFormation({ fixtureId, team, formation }) {
    return fetch(server + "/games/" + fixtureId, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        $set: { ["live_data." + team + "_formation"]: formation },
      }),
    });
  }

  function updateGameCommentators({ id, commentators }) {
    return client
      .mutate({
        mutation: UPDATE_COMMENTATORS,
        variables: { id, commentators },
      })
      .then((response) => {
        return response.data.updateGame;
      })
      .catch((err) => console.error(err));
  }

  function updateSeasonStats({ id, stats, stories }) {
    return client
      .mutate({
        mutation: UPDATE_SEASON_STATS,
        variables: { id, stats, stories },
      })
      .then((response) => {
        return response.data.updateSeasonStats;
      })
      .catch((err) => console.error(err));
  }

  function getStatBox({ id }) {
    return fetch(server + "/statboxs/" + (id || "?$orderby=name"))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function getStandings({ season }) {
    let url = server + "/standings?$single&$filter=season $eq " + season;
    return fetch(url)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function deleteStatBox({ id }) {
    return fetch(server + "/statboxs/" + id, { method: "DELETE" });
  }

  function createFormation({ data }) {
    delete data._id;
    return fetch(server + "/formations/", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  function deleteFormation({ data }) {
    return fetch(server + "/formations/" + data._id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  function updateStadium({ data }) {
    if (data._id) {
      return client
        .mutate({
          mutation: UPDATE_STADIUM,
          variables: { id: data._id, name: data.name, location: data.location },
        })
        .then((response) => {
          return response.data.updateStadium;
        })
        .catch((err) => console.error(err));
    } else {
      return client
        .mutate({
          mutation: ADD_STADIUM,
          variables: { name: data.name, location: data.location },
        })
        .then((response) => {
          return response.data.addStadium;
        })
        .catch((err) => console.error(err));
    }
  }

  function updateCountry({ data }) {
    if (data._id) {
      return client
        .mutate({
          mutation: UPDATE_COUNTRY,
          variables: { id: data._id, name: data.name, code: data.code },
        })
        .then((response) => {
          return response.data.updateCountry;
        })
        .catch((err) => console.error(err));
    } else {
      return client
        .mutate({
          mutation: ADD_COUNTRY,
          variables: { name: data.name, code: data.code },
        })
        .then((response) => {
          return response.data.addCountry;
        })
        .catch((err) => console.error(err));
    }
  }

  function testOPTA({ data }) {
    return fetch(server + "/hyper/v1/testOPTA", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  function getUser({ id }) {
    return fetch(server + "/users/" + (id || "?$orderby=first_name"))
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function updateUser({ data }) {
    if (data._id) {
      return fetch(server + "/users/" + data._id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    } else {
      return fetch(server + "/users/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    }
  }

  function deleteUser({ data }) {
    return fetch(server + "/users/" + data._id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  function downloadDatabase() {
    return fetch(server + "/hyper/v1/database/backup", {
      method: "GET",
    });
  }

  function getServerConfig() {
    return fetch(server + "/hyper/v1/server/config")
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function getPossession({ id }) {
    return fetch(server + "/possession?$filter=opta_ID $eq " + id)
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function getSettings() {
    return fetch(server + "/settings")
      .then((response) => response.json())
      .then((data) => {
        return data;
      });
  }

  function updateSettings({ data }) {
    if (data._id) {
      return fetch(server + "/settings/" + data._id, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    } else {
      return fetch(server + "/settings/", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
    }
  }

  function deleteSettings({ data }) {
    return fetch(server + "/settings/" + data._id, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });
  }

  function getFixtures({ competition, date }) {
    return client
      .query({
        query: GAMES_BY_DATE,
        variables: { date: date + "Z" },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.gamesByDate;
      })
      .catch((err) => console.error(err));
  }

  function getFixturesForMonth({ competition, date }) {
    return client
      .query({
        query: GAMES_BY_MONTH,
        variables: { date: date + "Z" },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.gamesByMonth;
      })
      .catch((err) => console.error(err));
  }

  function mergePlayers({ manual_player, opta_player }) {
    let player = {
      opta_first_name: opta_player.opta_first_name,
      opta_last_name: opta_player.opta_last_name,
      opta_ID: opta_player.opta_ID,
      date_of_birth: opta_player.date_of_birth,
      height: opta_player.height,
      weight: opta_player.weight,
      first_name: manual_player.first_name,
      last_name: manual_player.last_name,
      first_name_upper: manual_player.first_name_upper,
      last_name_upper: manual_player.last_name_upper,
      short_name: manual_player.short_name,
      short_name_upper: manual_player.short_name_upper,
      nationality: manual_player.nationality,
      stories: manual_player.stories,
      head_shots: manual_player.head_shots,
      _id: manual_player._id,
    };

    return deleteSquadPlayer({ playerId: opta_player.squad_id }).then(() => {
      return deletePlayer({ playerId: opta_player._id }).then(() => {
        return updatePlayer(player);
      });
    });
  }

  function createTeam({ data }) {
    return client
      .mutate({
        mutation: ADD_TEAM,
        variables: {
          name: data.name,
          short_name: data.short_name,
          club_name: data.club_name,
          code: data.code,
          variables: data.variables,
        },
      })
      .then((response) => {
        return response.data.addTeam;
      })
      .catch((err) => console.error(err));
  }

  function addTeamToSeason({ season, team }) {
    return client
      .mutate({
        mutation: ADD_TEAM_TO_SEASON,
        variables: {
          season: season,
          team: team,
        },
      })
      .then((response) => {
        return response.data.addTeamToSeason;
      })
      .catch((err) => console.error(err));
  }

  function getSeasonStatRankingsBySeason({ season_id }) {
    return client
      .query({
        query: SEASON_STATS,
        fetchPolicy: "network-only",
        variables: { id: season_id },
      })
      .then((response) => {
        return response.data.seasonStatRankingsBySeason;
      })
      .catch((err) => console.error(err));
  }

  return (
    <DataContext.Provider
      value={{
        updatePlayer,
        updateLineup,
        getLineup,
        getFixture,
        getTeams,
        getSquad,
        getPlayersBySearch,
        getSquads,
        createFixture,
        deleteFixture,
        getOfficial,
        updateGameOfficial,
        updateHeadCoach,
        getCompetition,
        getSeason,
        getStadium,
        getOfficial,
        getCoach,
        getTransfer,
        updateTeam,
        getCountry,
        getUser,
        addToSquad,
        deleteSquadPlayer,
        getFormation,
        updateFormation,
        updateGameFormation,
        getStatBox,
        deleteStatBox,
        getStandings,
        updateSquadPlayer,
        createFormation,
        deleteFormation,
        updateStadium,
        testOPTA,
        updateCountry,
        getUser,
        updateUser,
        deleteUser,
        downloadDatabase,
        getServerConfig,
        updateOfficial,
        mergePlayers,
        updateCompetition,
        getSettings,
        updateSettings,
        deleteSettings,
        getPossession,
        updateGameCommentator,
        getSecondSpectrumAveragePositions,
        updateGameSecondSpectrum,
        getFixtures,
        deleteCompetition,
        updateSeason,
        deleteSeason,
        createTeam,
        addTeamToSeason,
        updateGame,
        updateLineup,
        updateKit,
        getFixturesForMonth,
        updateGameCommentators,
        updateSeasonStats,
        getPlayers,
        updateInterchanges,
        getPundits,
        updatePundit,
        deletePundit,
        updateGamePundits,
        getSeasonStatRankingsBySeason,
        getCornerBoxes,
        updateCornerBox,
        deleteCornerBox,
        getSquadBySeason,
        getTeamsBySeason,
        updateGameLocation
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};
export { DataContext, DataProvider };
