import React, { useState, useEffect } from "react";
import Input from "../UI/Form/Input";
import Grid from "styled-components-grid";
import { DataContext } from "../../contexts/DataContext";
import { UIContext } from "../../contexts/UIContext";

import PlayerEdit from "../Player/PlayerEdit";
import SavePanel from "../UI/Form/SavePanel";
import Modal from "../UI/Modal/Modal";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import { Button } from "../../Theme/Hyper";

export default function StaffEditor({ team, onSave, onCancel, staffMember }) {
  const [staff, setStaff] = useState();

  useEffect(() => {
    if (staffMember) {
      setStaff(staffMember);
    } else {
      setStaff({});
    }
  }, [staffMember]);

  return (
    <Modal
      style={{ width: "70%" }}
      show={staffMember}
      onCancel={() => {
        onCancel();
      }}
    >
      {staffMember && (
        <Panel style={{ height: "40vh" }}>
          <PanelTitle title={"Staff Edit"}></PanelTitle>
          <PanelContent style={{ flexDirection: "column" }}>
            <Grid>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={staff?.first_name}
                  title="First Name"
                  onChange={(e) => {
                    setStaff({
                      ...staff,
                      first_name: e.currentTarget.value,
                      first_name_upper: e.currentTarget.value.toUpperCase(),
                    });
                  }}
                />
              </Grid.Unit>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={staff?.last_name}
                  title="Last Name"
                  onChange={(e) => {
                    setStaff({
                      ...staff,
                      last_name: e.currentTarget.value,
                      last_name_upper: e.currentTarget.value.toUpperCase(),
                    });
                  }}
                />
              </Grid.Unit>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={staff?.first_name_upper}
                  title="First Name Uppercase"
                  onChange={(e) => {
                    setStaff({
                      ...staff,
                      first_name_upper: e.currentTarget.value,
                    });
                  }}
                />
              </Grid.Unit>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={staff?.last_name_upper}
                  title="Last Name Uppercase"
                  onChange={(e) => {
                    setStaff({
                      ...staff,
                      last_name_upper: e.currentTarget.value,
                    });
                  }}
                />
              </Grid.Unit>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={staff?.title}
                  title="Caption"
                  onChange={(e) => {
                    setStaff({ ...staff, title: e.currentTarget.value });
                  }}
                />
              </Grid.Unit>
            </Grid>
          </PanelContent>

          <SavePanel
            showDelete={staffMember.title}
            onDelete={() => {
              let newTeam = JSON.parse(JSON.stringify(team));
              newTeam.variables.staff = newTeam.variables.staff.filter((s) => {
                return (
                  s.title != staffMember.title &&
                  s.first_name != staffMember.first_name &&
                  s.last_name != staffMember.last_name
                );
              });
              onSave(newTeam);
            }}
            onSave={() => {
              let newTeam = JSON.parse(JSON.stringify(team));

              newTeam.variables.staff = [...(newTeam.variables.staff || [])];

              let index = newTeam.variables.staff.findIndex(
                (s) =>
                  s.title == staffMember.title &&
                  s.first_name == staffMember.first_name &&
                  s.last_name == staffMember.last_name
              );
              if (index > -1) {
                newTeam.variables.staff[index] = staff;
              } else {
                newTeam.variables.staff.push(staff);
              }

              onSave(newTeam);
            }}
            onCancel={() => {
              onCancel();
            }}
          ></SavePanel>
        </Panel>
      )}
    </Modal>
  );
}
