import React, { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import { UIContext } from "../../contexts/UIContext";

import PlayerEdit from "../Player/PlayerEdit";
import SavePanel from "../UI/Form/SavePanel";
import Modal from "../UI/Modal/Modal";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";

export default function PlayerEditor({
  player,
  selectPlayer,
  team,
  selectedSeason,
  onSave,
  onCancel,
}) {
  const {
    updatePlayer,
    addToSquad,
    deleteSquadPlayer,
    updateSquadPlayer,
    updateSeasonStats,
  } = useContext(DataContext);

  return (
    <Modal
      style={{ width: "70%" }}
      show={player}
      onCancel={() => {
        selectPlayer();
        onCancel();
      }}
    >
      {player && (
        <Panel>
          <PanelTitle title={"Player Edit"}>
            <div>
              {player._id} - {player?.player?._id}
            </div>
          </PanelTitle>
          <PanelContent>
            <PlayerEdit
              team={team}
              player={{ ...player }}
              onChange={(p) => {
                selectPlayer(p);
              }}
            />
          </PanelContent>

          <SavePanel
            showDelete={true}
            onDelete={() => {
              deleteSquadPlayer({ playerId: player._id }).then(() => {
                selectPlayer();
                onSave();
              });
            }}
            onSave={() => {
              delete player.order;
              let create = !player._id;

              let promises = [];
              if (!create) {
                promises.push(updateSquadPlayer(player));
              }
              console.log(player.player);

              promises.push(
                updatePlayer({
                  ...player.player,
                  stories: player?.player?.stories || [],
                }).then((newPlayer) => {
                  if (create) {
                    return addToSquad({
                      playerId: newPlayer._id,
                      teamId: team._id,
                      seasonId: selectedSeason,
                      shirt_number: player.shirt_number,
                      position: player.position,
                      type: player.type,
                    });
                  }
                })
              );
              promises.push(
                updateSeasonStats({
                  id: player._id,
                  stories: player.stories,
                })
              );
              Promise.all(promises)
                .then(() => {
                  selectPlayer();
                  onSave();
                })
                .catch(() => {
                  selectPlayer();
                  onSave();
                });
            }}
            onCancel={() => {
              selectPlayer();
              onCancel();
            }}
          ></SavePanel>
        </Panel>
      )}
    </Modal>
  );
}
