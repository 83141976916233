import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import styled from "styled-components";

import { useCallback } from "react";
import PunditEditor from "../Modals/PunditEditor";

const Button = styled.div`
  display: flex;
  margin-right: 1em;
  min-width: 80px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
  align-items: center;
  padding: 0.5em;
  border-radius: 4px;
  justify-content: center;
  cursor: pointer;
  box-shadow: "0 1px 2px 0 rgba(0, 0, 0, 0.1)";
  background-color: ${(props) =>
    props.green ? "#14ec8e" : props.red ? "#db0a41" : "#dee2e6"};
  color: ${(props) => (props.green || props.red ? "#fff" : "")};
  font-size: 0.8em;
  :hover {
    cursor: pointer;
    background-color: ${(props) =>
      props.green ? "#0ed47e" : props.red ? "#b10835" : "#0ed47e"};
    color: ${(props) => (props.green || props.red ? "#fff" : "#fff")};
  }
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;
const Row = styled.div`
  display: flex;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const Tabs = styled.div`
  display: flex;
`;

const Tab = styled.div`
  background-color: ${(props) => (props.selected ? "#fff" : "#f2f2f2")};
  color: ${(props) => (props.selected ? "#606770" : "#999da0")};
  padding: 0.5em;
  display: flex;
  justify-content: center;
  min-width: 50px;
  border-top: 1px solid #dadde1;
  border-right: 1px solid #dadde1;
  border-left: ${(props) => (props.left ? "1px solid #dadde1" : "")};
  text-decoration: none;
  cursor: pointer;
`;

const Main = styled.div``;
export default function Pundits({ fixture }) {
  const { getPundits, updateGamePundits } = useContext(DataContext);
  const [pundits, setPundits] = useState();
  const [view, setView] = useState(0);
  const [selectedPundits, setSelectedPundits] = useState([]);
  const [selectedPundit, setSelectedPundit] = useState();
  const getData = useCallback(() => {
    getPundits().then((data) => {
      setPundits(data);
    });
  }, [getPundits]);

  useEffect(() => {
    getData();
  }, [getData]);

  useEffect(() => {
    setSelectedPundits(fixture?.pundits);
  }, [fixture]);

  return (
    <Main>
      <Button
        green
        style={{ margin: "1em", width: "200px" }}
        onClick={() => {
          setSelectedPundit({});
        }}
      >
        + Create Pundit
      </Button>
      <Panel style={{ marginTop: "1em" }}>
        <PanelTitle title={"Pundits List"} />
        <PanelContent>
          <Column>
            <Table>
              <thead>
                <tr>
                  <TH style={{ width: "200px" }}> </TH>
                  <TH style={{ width: "200px" }}>Fist Name</TH>
                  <TH style={{ width: "200px" }}>Last Name</TH>
                  <TH style={{ width: "200px" }}>SS Logo</TH>

                  <TH>Stories</TH>
                </tr>
              </thead>
              <tbody>
                {pundits &&
                  [...(pundits || [])]
                    ?.sort((a, b) => {
                      if (a.last_name < b.last_name) {
                        return -1;
                      }
                      if (a.last_name > b.last_name) {
                        return 1;
                      }
                      return 0;
                    })
                    .map((pundit) => {
                      return (
                        <tr>
                          <td>
                            <Row>
                              <Button
                                onClick={() => {
                                  let newPundits = [...(selectedPundits || [])];

                                  let index = newPundits?.findIndex(
                                    (p) => p._id === pundit?._id
                                  );
                                  if (index === -1) {
                                    newPundits.push({ _id: pundit?._id });
                                  } else {
                                    newPundits = newPundits?.filter(
                                      (p) => p._id !== pundit?._id
                                    );
                                  }
                                  updateGamePundits({
                                    id: fixture._id,
                                    pundits: newPundits,
                                  });
                                  setSelectedPundits(newPundits);
                                }}
                                green={
                                  selectedPundits?.findIndex(
                                    (p) => p._id === pundit?._id
                                  ) > -1
                                }
                              >
                                Select
                              </Button>

                              <Button
                                onClick={() => {
                                  setSelectedPundit(pundit);
                                }}
                              >
                                Edit
                              </Button>
                            </Row>
                          </td>
                          <td style={{ width: "200px" }}>
                            {pundit.first_name}
                          </td>
                          <td style={{ width: "200px" }}>{pundit.last_name}</td>
                          <td>{pundit?.ss_logo ? "yes" : "no"}</td>

                          <td>
                            {pundit?.stories?.map((s) => s?.story)?.join(", ")}
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            </Table>
          </Column>
        </PanelContent>
      </Panel>
      {selectedPundit && (
        <PunditEditor
          player={selectedPundit}
          selectPlayer={(p) => {
            setSelectedPundit(p);
            getData();
          }}
          onCancel={() => {
            setSelectedPundit();
          }}
          onSave={() => {
            getData();
            setSelectedPundit();
          }}
        />
      )}
    </Main>
  );
}
