import React from "react";
import { useState } from "react";
import { Button } from "../../Theme/Hyper";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import styled from "styled-components";
import { AiOutlineCloudUpload } from "react-icons/ai";
import Input from "../UI/Form/Input";
import CompetitionList from "../Competition/CompetitionList";
import SeasonList from "../Season/SeasonList";
import { useEffect } from "react";
import { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import { useParams } from "react-router-dom";

const Row = styled.div`
  display: flex;
  align-items: center;
`;

function DataImport() {
  const [selectedCompetition, setSelectedCompetition] = useState();
  const [selectedSeason, setSelectedSeason] = useState();
  const [seasons, setSeasons] = useState([]);
  const {
    getSeason,
    getTeams,
    getSquad,
    getPlayers,
    updatePlayer,
    addToSquad,
    updateSeasonStats,
  } = useContext(DataContext);
  const { seasonId, teamId } = useParams();

  const [teams, setTeams] = useState();
  const [status, setStatus] = useState("");
  useEffect(() => {
    getData();
  }, []);

  function getData() {
    getTeams().then((data) => {
      setTeams(data);
    });
  }

  useEffect(() => {
    getSeason().then((data) => {
      setSeasons(data);
      if (selectedCompetition) {
        let newSeason = data
          ?.filter((s) => s.competition._id === selectedCompetition._id)
          ?.sort((a, b) => {
            return (
              parseInt(b.name.split("/")[0]) - parseInt(a.name.split("/")[0])
            );
          })[0];
        setSelectedSeason(newSeason);
      }
    });
  }, [selectedCompetition, teams]);

  useEffect(() => {
    if (seasonId && seasons?.length) {
      setSelectedSeason(seasons?.find((s) => s._id === seasonId));
      setSelectedCompetition(
        seasons?.find((s) => s._id === seasonId)?.competition
      );
    }
  }, [seasonId, seasons]);

  function convertName(str) {
    //split the above string into an array of strings
    //whenever a blank space is encountered
    str = str.toLowerCase();
    const arr = str.split(" ");

    //loop through each element of the array and capitalize the first letter.

    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }

    //Join all the elements of the array back into a string
    //using a blankspace as a separator
    const str2 = arr.join(" ");

    return str2;
  }
  return (
    <div>
      <Panel>
        <PanelContent>
          <Row>
            <CompetitionList
              selectedCompetition={selectedCompetition}
              onSelect={setSelectedCompetition}
              style={{ margin: "1em" }}
            />
            <SeasonList
              seasons={seasons}
              selectedSeason={selectedSeason}
              onSelect={(season) => {
                setSelectedSeason(season);
              }}
              competition={selectedCompetition}
              style={{ margin: "1em" }}
            />
            {selectedCompetition && selectedSeason && (
              <FileUpload
                id="superleague-csv"
                label="Import Super League CSV"
                onChange={async (array) => {
                  setStatus("importing");
                  let team;
                  let prev_team;
                  let squad;
                  let existing_players = await getPlayers();
                  for (let i = 1; i < array.length; i++) {
                    let player = array[i];
                    if (player) {
                      team = teams?.find((t) => {
                        return (
                          t.name === player[3]?.trim() &&
                          selectedSeason?.teams?.findIndex((te) => {
                            return te?._id === t._id;
                          }) > -1
                        );
                      });

                      try {
                        let player_first_name = player[2]
                          ?.split(" ")?.[0]
                          ?.trim();

                        let player_last_name = player[2]
                          ?.substring(player[2].indexOf(" ") + 1)
                          ?.trim();
                        if (
                          player[7] === "HEAD COACH" ||
                          player[7] === "COACH"
                        ) {
                          player_first_name = convertName(player_first_name);
                        }
                        if (
                          player[7] === "HEAD COACH" ||
                          player[7] === "COACH"
                        ) {
                          player_last_name = convertName(player_last_name);
                        }
                        if (team && player_first_name) {
                          let old_players = existing_players?.filter(
                            (p) =>
                              p.first_name === player_first_name &&
                              p.last_name === player_last_name
                          );

                          let stories = [];

                          stories.push({
                            number: 1,
                            story:
                              player[7]
                                ?.trim()
                                ?.replaceAll(/""/g, "___")
                                ?.replaceAll('"', "")
                                ?.replaceAll("___", '"') +
                              " " +
                              player[8]
                                ?.trim()
                                ?.replaceAll(/""/g, "___")
                                ?.replaceAll('"', "")
                                ?.replaceAll("___", '"'),
                            line_1: player[7]
                              ?.trim()
                              ?.replaceAll(/""/g, "___")
                              ?.replaceAll('"', "")
                              ?.replaceAll("___", '"'),
                            line_2: player[8]
                              ?.trim()
                              ?.replaceAll(/""/g, "___")
                              ?.replaceAll('"', "")
                              ?.replaceAll("___", '"'),
                            type: "stat",
                          });

                          stories.push({
                            number: 1,
                            story:
                              player[9]
                                ?.trim()
                                ?.replaceAll(/""/g, "___")
                                ?.replaceAll('"', "")
                                ?.replaceAll("___", '"') +
                              " " +
                              player[10]
                                ?.trim()
                                ?.replaceAll(/""/g, "___")
                                ?.replaceAll('"', "")
                                ?.replaceAll("___", '"'),
                            line_1: player[9]
                              ?.trim()
                              ?.replaceAll(/""/g, "___")
                              ?.replaceAll('"', "")
                              ?.replaceAll("___", '"'),
                            line_2: player[10]
                              ?.trim()
                              ?.replaceAll(/""/g, "___")
                              ?.replaceAll('"', "")
                              ?.replaceAll("___", '"'),
                            type: "stat",
                          });

                          if (team?.name !== prev_team?.name) {
                            squad = await getSquad({
                              teamId: team?._id,
                              seasonId: selectedSeason?._id,
                            });
                          }
                          let old_player;

                          let squad_player = squad?.find((s) => {
                            let p = old_players?.find(
                              (o) => o?._id === s?.player?._id
                            );

                            if (p) {
                              old_player = p;
                              return true;
                            }
                          });

                          //DONT ADD FROM PROCERS AS WE GET IT FROM OPTA
                          if (
                            old_player &&
                            squad_player &&
                            JSON.stringify(squad_player?.stories) !==
                              JSON.stringify(stories)
                          ) {
                            old_player = await updatePlayer({
                              _id: old_player?._id,
                              first_name: null,
                              first_name_upper: null,
                              last_name: null,
                              last_name_upper: null,
                              short_name: null,
                              short_name_upper: null,
                              nationality: null,
                              title: null,
                              image: null,
                              stories,
                            });
                          }

                          // if (!squad_player) {
                          //   squad_player = await addToSquad({
                          //     playerId: old_player?._id,
                          //     teamId: team?._id,
                          //     seasonId: selectedSeason?._id,
                          //     shirt_number: player[1],
                          //     type:
                          //       player[7] === "HEAD COACH" ||
                          //       player[7] === "COACH"
                          //         ? "coach"
                          //         : "player",
                          //   });
                          // }

                          if (squad_player) {
                            let new_stats = {};

                            new_stats.tries = player[12];
                            new_stats.goals = player[15];

                            updateSeasonStats({
                              id: squad_player._id,
                              stats: new_stats,
                              stories,
                            });
                          }
                          prev_team = team;
                        }
                      } catch (err) {
                        debugger;
                      }
                    }
                  }
                  setStatus("complete");
                  setTimeout(() => {
                    setStatus("");
                  }, 5000);
                }}
              />
            )}
            {selectedCompetition && selectedSeason && (
              <FileUploadTab
                id="superleague-tab"
                label="Import Super League Tab Delimited .txt"
                onChange={async (array) => {
                  setStatus("importing");
                  let team;
                  let prev_team;
                  let squad;
                  let existing_players = await getPlayers();
                  for (let i = 1; i < array.length; i++) {
                    let player = array[i];
                    if (player) {
                      team = teams?.find((t) => {
                        return (
                          t.name === player[3]?.trim() &&
                          selectedSeason?.teams?.findIndex((te) => {
                            return te?._id === t._id;
                          }) > -1
                        );
                      });

                      try {
                        let player_first_name = player[2]
                          ?.split(" ")?.[0]
                          ?.trim();

                        let player_last_name = player[2]
                          ?.substring(player[2].indexOf(" ") + 1)
                          ?.trim();
                        if (
                          player_first_name === "Mark" &&
                          player_last_name === "Percival"
                        ) {
                          debugger;
                        }
                        if (
                          player[7] === "HEAD COACH" ||
                          player[7] === "COACH"
                        ) {
                          player_first_name = convertName(player_first_name);
                        }
                        if (
                          player[7] === "HEAD COACH" ||
                          player[7] === "COACH"
                        ) {
                          player_last_name = convertName(player_last_name);
                        }
                        if (team && player_first_name) {
                          let old_players = existing_players?.filter(
                            (p) =>
                              p.first_name === player_first_name &&
                              p.last_name === player_last_name
                          );

                          let stories = [];

                          stories.push({
                            number: 1,
                            story:
                              player[7]
                                ?.trim()
                                ?.replaceAll(/""/g, "___")
                                ?.replaceAll('"', "")
                                ?.replaceAll("___", '"') +
                              " " +
                              player[8]
                                ?.trim()
                                ?.replaceAll(/""/g, "___")
                                ?.replaceAll('"', "")
                                ?.replaceAll("___", '"'),
                            line_1: player[7]
                              ?.trim()
                              ?.replaceAll(/""/g, "___")
                              ?.replaceAll('"', "")
                              ?.replaceAll("___", '"'),
                            line_2: player[8]
                              ?.trim()
                              ?.replaceAll(/""/g, "___")
                              ?.replaceAll('"', "")
                              ?.replaceAll("___", '"'),
                            type: "stat",
                          });

                          stories.push({
                            number: 1,
                            story:
                              player[9]
                                ?.trim()
                                ?.replaceAll(/""/g, "___")
                                ?.replaceAll('"', "")
                                ?.replaceAll("___", '"') +
                              " " +
                              player[10]
                                ?.trim()
                                ?.replaceAll(/""/g, "___")
                                ?.replaceAll('"', "")
                                ?.replaceAll("___", '"'),
                            line_1: player[9]
                              ?.trim()
                              ?.replaceAll(/""/g, "___")
                              ?.replaceAll('"', "")
                              ?.replaceAll("___", '"'),
                            line_2: player[10]
                              ?.trim()
                              ?.replaceAll(/""/g, "___")
                              ?.replaceAll('"', "")
                              ?.replaceAll("___", '"'),
                            type: "stat",
                          });

                          if (team?.name !== prev_team?.name) {
                            squad = await getSquad({
                              teamId: team?._id,
                              seasonId: selectedSeason?._id,
                            });
                          }
                          let old_player;

                          let squad_player = squad?.find((s) => {
                            let p = old_players?.find(
                              (o) => o?._id === s?.player?._id
                            );

                            if (p) {
                              old_player = p;
                              return true;
                            }
                          });

                          //DONT ADD FROM PROCERS AS WE GET IT FROM OPTA
                          if (
                            old_player &&
                            squad_player &&
                            JSON.stringify(squad_player?.stories) !==
                              JSON.stringify(stories)
                          ) {
                            old_player = await updatePlayer({
                              _id: old_player?._id,
                              first_name: null,
                              first_name_upper: null,
                              last_name: null,
                              last_name_upper: null,
                              short_name: null,
                              short_name_upper: null,
                              nationality: null,
                              title: null,
                              image: null,
                              stories,
                            });
                          }

                          if (squad_player) {
                            let new_stats = {};

                            new_stats.tries = player[12];
                            new_stats.goals = player[15];

                            updateSeasonStats({
                              id: squad_player._id,
                              stats: new_stats,
                              stories,
                            });
                          }
                          prev_team = team;
                        }
                      } catch (err) {
                        debugger;
                      }
                    }
                  }
                  setStatus("complete");
                  setTimeout(() => {
                    setStatus("");
                  }, 5000);
                }}
              />
            )}
            {status}
          </Row>
        </PanelContent>
      </Panel>
    </div>
  );
}

const FileContainer = styled.div`
  display: flex;
  align-items: flex-end;
  align-items: center;
`;
const Swatch = styled.div`
  width: 50px;
  height: 30px;
  border: 1px solid grey;
  border-radius: 3px;
  margin: 0.5em;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
`;

function FileUpload({ onChange, label, id }) {
  const [open, setOpen] = useState(false);

  function upload(e) {
    const reader = new FileReader();

    const csv = e.target.files[0];

    reader.onload = (e) => {
      const text = e.target.result;
      const array = csvToArray(text);
    };

    reader.readAsText(csv);
    function csvToArray(str, delimiter = ",") {
      function hex_to_ascii(str1) {
        var hex = str1.toString();
        var str = "";
        for (var n = 0; n < hex.length; n += 2) {
          str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
        }

        return str;
      }

      let array = str.split(hex_to_ascii("0A")).map(function (line) {
        return line.split(delimiter);
      });

      onChange(array);
    }
  }
  return (
    <FileContainer>
      <input
        id={id}
        type="file"
        name="file"
        accept=".csv"
        style={{ display: "none" }}
        onChange={(e) => {
          upload(e);
        }}
      />

      <Button onClick={() => document.getElementById(id).click()}>
        {label}
      </Button>
    </FileContainer>
  );
}

function FileUploadTab({ onChange, label, id }) {
  const [open, setOpen] = useState(false);

  function upload(e) {
    debugger;
    const reader = new FileReader();

    const file = e.target.files[0];

    reader.onload = (e) => {
      const text = e.target.result;
      const array = text.split("\n").map((line) => line.split("\t"));
      onChange(array);
    };

    reader.readAsText(file);
  }
  return (
    <FileContainer>
      <input
        id={id}
        type="file"
        name="file"
        accept=".txt"
        style={{ display: "none" }}
        onChange={(e) => {
          upload(e);
        }}
      />

      <Button onClick={() => document.getElementById(id).click()}>
        {label}
      </Button>
    </FileContainer>
  );
}

export default DataImport;
