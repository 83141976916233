import { updateLocale } from "moment";
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Grid from "styled-components-grid";
import { Button, ScrollBar } from "../../Theme/Hyper";
import Input from "../UI/Form/Input";
import SelectUI from "../UI/Form/SelectUI";
import Autosuggest from "react-autosuggest";
import "./AutoComplete.css";
import Image from "../UI/Image";
import { DataContext } from "../../contexts/DataContext";
const Main = styled(ScrollBar)`
  width: 100%;
`;
const Options = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;
  padding-bottom: 1em;
  border-bottom: 1px solid #dee2e6;
`;
const Option = styled.div`
  display: flex;
  flex-direction: column;
  border-right: 1px solid #dee2e6;
  margin-right: 1em;
`;
const Buttons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`;
const Title = styled.div``;

const Row = styled.div`
  display: flex;
  align-items: center;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  th:nth-child(1) {
    width: 100px;
  }
  th:nth-child(2) {
    width: 300px;
  }
  th:nth-child(3) {
    width: 50px;
  }
  th:nth-child(4) {
    width: 300px;
  }
  th:nth-child(n + 5) {
    width: 80px;
  }
  th:last-child {
    width: 300px;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    font-size: 0.8em;
    overflow-y: visible;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;
export default function CornerBoxEdit({ game, box, onChange }) {
  const { getTeamsBySeason, getSquadBySeason } = useContext(DataContext);
  const [rows, setRows] = useState(box.number_rows || 5);
  const [columns, setColumns] = useState(box.number_columns || 1);
  const [textType, setTextType] = useState(box.text_type ?? "manual");
  const [teams, setTeams] = useState();
  const [players, setPlayers] = useState();
  const [titleFolder, setTitleFolder] = useState(box.title_badge_folder ?? "");
  useEffect(() => {
    getTeamsBySeason({ seasonId: game?.season?._id }).then((data) => {
      setTeams([...(data || [])]);
    });
  }, [getTeamsBySeason]);

  useEffect(() => {
    getSquadBySeason({ seasonId: game?.season?._id }).then((data) => {
      setPlayers([...(data || [])]);
    });
  }, [getSquadBySeason]);
  return (
    <Main>
      <Row>
        <Title style={{ marginRight: "1em" }}>Type</Title>
        <Button green={box?.type === "game"}>Game</Button>
        <Button green={box?.type === "shared"}>Shared</Button>
      </Row>

      <Row>
        <Input
          style={{ width: "100px" }}
          title="Order"
          value={box.number}
          onChange={(e) => {
            let newBox = { ...box };
            newBox.number = e.currentTarget.value;
            onChange(newBox);
          }}
        ></Input>
        <Input
          title="Stat Name"
          value={box.name}
          onChange={(e) => {
            let newBox = { ...box };
            newBox.name = e.currentTarget.value;
            onChange(newBox);
          }}
        ></Input>
      </Row>
      <Options>
        <Option>
          <Title>Rows</Title>
          <Buttons>
            <Button
              onClick={() => {
                if (rows > 0) {
                  let newBox = { ...box };
                  newBox.number_rows = rows - 1;
                  onChange(newBox);
                  setRows(rows - 1);
                }
              }}
            >
              -
            </Button>
            <span style={{ marginRight: "1em" }}>{rows}</span>
            <Button
              onClick={() => {
                if (rows < 10) {
                  let newBox = { ...box };
                  newBox.number_rows = rows + 1;
                  onChange(newBox);
                  setRows(rows + 1);
                }
              }}
            >
              +
            </Button>
          </Buttons>
        </Option>
        <Option>
          <Title>Columns</Title>
          <Buttons>
            <Button
              onClick={() => {
                if (columns > 0) {
                  let newBox = { ...box };
                  newBox.number_columns = columns - 1;
                  onChange(newBox);
                  setColumns(columns - 1);
                }
              }}
            >
              -
            </Button>
            <span style={{ marginRight: "1em" }}>{columns}</span>
            <Button
              onClick={() => {
                if (columns < 4) {
                  let newBox = { ...box };
                  newBox.number_columns = columns + 1;
                  onChange(newBox);
                  setColumns(columns + 1);
                }
              }}
            >
              +
            </Button>
          </Buttons>
        </Option>
      </Options>
      <Table>
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th></th>
            <th></th>

            {new Array(columns)?.fill(1)?.map((_, index) => {
              return <th></th>;
            })}
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <SelectUI
                title="Image folder"
                value={
                  titleFolder === "competition"
                    ? {
                        value: "competition",
                        label: "Competition",
                      }
                    : titleFolder === "team"
                    ? {
                        value: "team",
                        label: "Team",
                      }
                    : { value: "", label: "Custom" }
                }
                options={[
                  { value: "", label: "Custom" },
                  {
                    value: "competition",
                    label: "Competition",
                  },
                  {
                    value: "team",
                    label: "Team",
                  },
                ]}
                placeholder="Select Team"
                onChange={(value) => {
                  let new_box = JSON.parse(JSON.stringify(box));
                  new_box.title_badge_folder = value;
                  onChange(new_box);
                  setTitleFolder(value);
                }}
              />
            </td>
            <td>
              <Input
                title={
                  titleFolder === "competition"
                    ? "IMAGE*___MEDIA/RUGBY_LEAGUE/COMPETITION_LOGOS/"
                    : titleFolder === "team"
                    ? "IMAGE*___MEDIA/RUGBY_LEAGUE/TEAM_BADGES/"
                    : ""
                }
                placeholder={"Title Logo"}
                tabIndex="1"
                value={box?.title_badge}
                onChange={(event) => {
                  let newBox = { ...box };
                  newBox.title_badge = event.currentTarget.value;
                  onChange(newBox);
                }}
              />
            </td>
            <td colSpan={2}>
              <Input
                multiline={true}
                placeholder={"Title"}
                tabIndex="1"
                value={box?.heading}
                onChange={(event) => {
                  let newBox = { ...box };
                  newBox.heading = event.currentTarget.value;
                  onChange(newBox);
                }}
              ></Input>
            </td>
            {new Array(columns)?.fill(1)?.map((_, index) => {
              return <td></td>;
            })}
            <td></td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td colSpan={2}>
              <Input
                placeholder={"Subline"}
                tabIndex="1"
                value={box?.subtitle}
                onChange={(event) => {
                  let newBox = { ...box };
                  newBox.subtitle = event.currentTarget.value;
                  onChange(newBox);
                }}
              ></Input>
            </td>
            {new Array(columns)?.fill(1)?.map((_, index) => {
              return (
                <td>
                  <Input
                    placeholder={"Title " + (index + 1)}
                    tabIndex="1"
                    value={box?.["column_" + (index + 1)]}
                    onChange={(event) => {
                      let newBox = { ...box };
                      newBox["column_" + (index + 1)] =
                        event.currentTarget.value;
                      onChange(newBox);
                    }}
                  ></Input>
                </td>
              );
            })}
            <td></td>
          </tr>

          <tr>
            <td></td>
            <td>
              <Row>
                <Button
                  green={box.show_badges === true}
                  onClick={(e) => {
                    let newBox = JSON.parse(JSON.stringify(box));
                    newBox.show_badges = true;
                    onChange(newBox);
                  }}
                >
                  SHOW BADGES
                </Button>
                <Button
                  green={box.show_badges === false}
                  onClick={(e) => {
                    let newBox = JSON.parse(JSON.stringify(box));
                    newBox.show_badges = false;
                    onChange(newBox);
                  }}
                >
                  HIDE BADGES
                </Button>
              </Row>
            </td>
            <td></td>
            <td>
              <Row>
                <Button
                  green={textType === "teams"}
                  onClick={() => {
                    let newBox = { ...box };
                    newBox.text_type = "teams";
                    onChange(newBox);
                    setTextType("teams");
                  }}
                >
                  Team
                </Button>
                <Button
                  green={textType === "players"}
                  onClick={() => {
                    let newBox = { ...box };
                    newBox.text_type = "players";
                    onChange(newBox);
                    setTextType("players");
                  }}
                >
                  Players
                </Button>
                <Button
                  green={textType === "manual"}
                  onClick={() => {
                    let newBox = { ...box };
                    newBox.text_type = "manual";
                    onChange(newBox);
                    setTextType("manual");
                  }}
                >
                  Manual
                </Button>
              </Row>
            </td>
            {new Array(columns)?.fill(1)?.map((_, index) => {
              return <td></td>;
            })}
            <td></td>
          </tr>
          {teams &&
            players &&
            new Array(rows)?.fill(1)?.map((row, index) => {
              return (
                <tr>
                  <td>
                    <Row>
                      <Button
                        green={box.rows?.[index]?.highlighted ?? false}
                        onClick={(e) => {
                          let newBox = JSON.parse(JSON.stringify(box));
                          newBox.rows[index].highlighted =
                            !newBox.rows?.[index]?.highlighted;
                          onChange(newBox);
                        }}
                      >
                        HL
                      </Button>
                      <Button
                        green={box.rows?.[index]?.dots ?? false}
                        onClick={(e) => {
                          let newBox = JSON.parse(JSON.stringify(box));
                          newBox.rows[index].dots = !newBox.rows?.[index]?.dots;
                          onChange(newBox);
                        }}
                      >
                        DOTS
                      </Button>
                    </Row>
                  </td>
                  <td>
                    {box?.show_badges && (
                      <Input
                        placeholder={"Badge"}
                        tabIndex="1"
                        value={box?.rows?.[index]?.badge ?? ""}
                        onChange={(event) => {
                          let newBox = JSON.parse(JSON.stringify(box));
                          newBox.rows[index].badge = event.currentTarget.value;
                          onChange(newBox);
                        }}
                      ></Input>
                    )}
                  </td>
                  <td>
                    <Input
                      placeholder={"#"}
                      tabIndex="1"
                      value={box?.rows?.[index]?.number ?? ""}
                      onChange={(event) => {
                        let newBox = JSON.parse(JSON.stringify(box));
                        newBox.rows[index].number = event.currentTarget.value;
                        onChange(newBox);
                      }}
                    ></Input>
                  </td>
                  <td>
                    <Row>
                      {textType === "teams" && (
                        <SelectUI
                          value={
                            box?.rows?.[index]?.team
                              ? {
                                  value: teams?.find(
                                    (t) => t?._id === box?.rows?.[index]?.team
                                  ),
                                  label: teams?.find(
                                    (t) => t?._id === box?.rows?.[index]?.team
                                  )?.short_name,
                                }
                              : ""
                          }
                          options={teams
                            .sort((a, b) => {
                              if (a.short_name < b.short_name) {
                                return -1;
                              }
                              if (a.short_name > b.short_name) {
                                return 1;
                              }
                              return 0;
                            })
                            ?.map((team) => {
                              return {
                                value: team,
                                label: team?.short_name,
                              };
                            })}
                          placeholder="Select Team"
                          onChange={(team) => {
                            let new_box = JSON.parse(JSON.stringify(box));
                            if (!new_box.rows[index]) {
                              new_box.rows.push({});
                            }
                            new_box.rows[index].badge = team?.variables?.badge;
                            new_box.rows[index].team = team?._id;
                            new_box.rows[index].text = team?.short_name;
                            onChange(new_box);
                          }}
                        />
                      )}
                      {textType === "players" && (
                        <SelectUI
                          value={
                            box?.rows?.[index]?.player
                              ? {
                                  value: players?.find(
                                    (t) => t?._id === box?.rows?.[index]?.player
                                  ),
                                  label:
                                    players?.find(
                                      (t) =>
                                        t?._id === box?.rows?.[index]?.player
                                    )?.player?.first_name +
                                    " " +
                                    players?.find(
                                      (t) =>
                                        t?._id === box?.rows?.[index]?.player
                                    )?.player?.last_name,
                                }
                              : ""
                          }
                          options={players
                            ?.filter((player) => {
                              return player?.active;
                            })
                            .sort((a, b) => {
                              if (a.player?.last_name < b.player?.last_name) {
                                return -1;
                              }
                              if (a.player?.last_name > b.player?.last_name) {
                                return 1;
                              }
                              return 0;
                            })
                            ?.map((player) => {
                              return {
                                value: player,
                                label:
                                  player?.player?.first_name +
                                  " " +
                                  player?.player?.last_name +
                                  " (" +
                                  player?.team?.short_name +
                                  ")",
                              };
                            })}
                          placeholder="Select Player"
                          onChange={(player) => {
                            let new_box = JSON.parse(JSON.stringify(box));
                            new_box.rows[index].player = player?._id;
                            new_box.rows[index].text =
                              player?.player?.first_name +
                              " " +
                              player?.player?.last_name;
                            new_box.rows[index].badge =
                              player?.team?.variables?.badge;
                            onChange(new_box);
                          }}
                        />
                      )}
                      {textType === "manual" && (
                        <Input
                          placeholder={"Text"}
                          tabIndex="1"
                          value={box?.rows?.[index]?.text ?? ""}
                          onChange={(event) => {
                            let newBox = JSON.parse(JSON.stringify(box));
                            newBox.rows[index].text = event.currentTarget.value;
                            onChange(newBox);
                          }}
                        ></Input>
                      )}

                      <Input
                        placeholder={"Small Text"}
                        tabIndex="1"
                        value={box?.rows?.[index]?.small_text ?? ""}
                        onChange={(event) => {
                          let newBox = JSON.parse(JSON.stringify(box));
                          newBox.rows[index].small_text =
                            event.currentTarget.value;
                          onChange(newBox);
                        }}
                      ></Input>
                    </Row>
                  </td>
                  {new Array(columns)?.fill(1)?.map((_, col_index) => {
                    return (
                      <td>
                        <Input
                          placeholder={"Col " + (col_index + 1)}
                          tabIndex="1"
                          value={
                            box?.rows?.[index]?.[
                              "col_" + (col_index + 1) + "text"
                            ] ?? ""
                          }
                          onChange={(event) => {
                            let newBox = JSON.parse(JSON.stringify(box));
                            newBox.rows[index][
                              "col_" + (col_index + 1) + "text"
                            ] = event.currentTarget.value;
                            onChange(newBox);
                          }}
                        ></Input>
                      </td>
                    );
                  })}
                  <td>
                    <Row>
                      <Button
                        onClick={() => {
                          let newBox = JSON.parse(JSON.stringify(box));
                          newBox.rows[index] = {
                            badge: "",
                            column_1: "",
                            column_2: "",
                            column_3: "",
                            column_4: "",
                            number: "",
                            text: "",
                            small_text: "",
                            col_1text: "",
                            col_2text: "",
                            col_3text: "",
                            col_4text: "",
                          };
                          onChange(newBox);
                        }}
                      >
                        Clear
                      </Button>
                      <Button
                        onClick={() => {
                          if (index > 0) {
                            let newBox = JSON.parse(JSON.stringify(box));
                            let row = { ...newBox.rows[index] };
                            let otherRow = { ...newBox.rows[index - 1] };
                            newBox.rows[index] = otherRow;
                            newBox.rows[index - 1] = row;
                            onChange(newBox);
                          }
                        }}
                      >
                        Up
                      </Button>
                      <Button
                        onClick={() => {
                          if (index + 1 < box.number_rows) {
                            let newBox = JSON.parse(JSON.stringify(box));
                            let row = { ...newBox.rows[index] };
                            let otherRow = { ...newBox.rows[index + 1] };
                            newBox.rows[index] = otherRow;
                            newBox.rows[index + 1] = row;
                            onChange(newBox);
                          }
                        }}
                      >
                        Down
                      </Button>
                    </Row>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Main>
  );
}
