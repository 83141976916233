import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import styled from "styled-components";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import Grid from "styled-components-grid";
const Main = styled(Panel)`
  font-size: 0.8em;
`;
const MainPanelTitle = styled(PanelTitle)`
  font-size: 0.8em;
`;
const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: auto;
  font-size: 0.8em;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 5px;
    font-size: 0.8em;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    padding: 4px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
  }
  td.value {
    text-align: right;
    padding-right: 23px;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
  }

  > tbody > tr > td:nth-child(1) {
    width: 56px;
  }
  > tbody > tr:nth-child(1) > td {
    font-weight: bold;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
  background-color: #293b52;
  color: #fff;
`;

function SeasonStats({ fixture }) {
  const { getSeasonStatRankingsBySeason } = useContext(DataContext);
  const [seasonStats, setSeasonStats] = useState();
  useEffect(() => {
    if (fixture) {
      getSeasonStatRankingsBySeason({ season_id: fixture?.season?._id }).then(
        (data) => {
          setSeasonStats(data);
        }
      );
    }
  }, [fixture, getSeasonStatRankingsBySeason]);
  return (
    <Grid>
      {seasonStats?.opta_player_stats?.map((stat) => {
        return (
          <Grid.Unit size={1 / 5} style={{ marginBottom: ".5em" }}>
            <Main>
              <PanelContent
                style={{
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                  padding: "7px",
                }}
              >
                <Table>
                  <thead>
                    <tr>
                      <TH colSpan={4}>{stat?.type}</TH>
                    </tr>
                  </thead>
                  <tbody>
                    {stat?.players?.slice(0, 5)?.map((player) => {
                      return (
                        <tr>
                          <td>{player?.rank}</td>
                          <td>{player?.team?.code}</td>
                          <td>
                            {(player?.squad?.player?.first_name !== ""
                              ? player?.squad?.player?.first_name + " "
                              : "") + player?.squad?.player?.last_name}
                          </td>
                          <td class="value">{player?.value}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </PanelContent>
            </Main>
          </Grid.Unit>
        );
      })}
    </Grid>
  );
}

export default SeasonStats;
