import React, { useContext } from "react";
import { DataContext } from "../../contexts/DataContext";
import CompetitionEdit from "../Competition/CompetitionEdit";
import SavePanel from "../UI/Form/SavePanel";
import Modal from "../UI/Modal/Modal";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";

export default function CompetitionEditor({
  competition,
  onSave,
  onCancel,
  selectCompetition,
}) {
  const { updateCompetition, deleteCompetition } = useContext(DataContext);

  return (
    <Modal
      style={{ width: "50%" }}
      show={competition}
      onCancel={() => {
        selectCompetition();
        onCancel();
      }}
    >
      {competition && (
        <Panel style={{ marginTop: "0%" }}>
          <PanelTitle title={"Competition Edit"}>
            <div>{competition._id}</div>
          </PanelTitle>
          <PanelContent>
            <CompetitionEdit
              competition={competition}
              onChange={(s) => {
                selectCompetition(s);
              }}
            />
          </PanelContent>

          <SavePanel
            showDelete={competition._id}
            onDelete={() => {
              deleteCompetition({ data: competition }).then(() => {
                selectCompetition();
                onSave();
              });
            }}
            onSave={() => {
              updateCompetition({ data: competition }).then(() => {
                selectCompetition();
                onSave();
              });
            }}
            onCancel={() => {
              selectCompetition();
              onCancel();
            }}
          ></SavePanel>
        </Panel>
      )}
    </Modal>
  );
}
