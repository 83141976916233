import React, { useState } from "react";
import Grid from "styled-components-grid";
import Input from "../UI/Form/Input";
import SelectUI from "../UI/Form/SelectUI";
import styled from "styled-components";
import Stories from "./Stories";

const StatsContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const Tabs = styled.div`
  display: flex;
`;

const Tab = styled.div`
  background-color: ${(props) => (props.selected ? "#fff" : "#f2f2f2")};
  color: ${(props) => (props.selected ? "#606770" : "#999da0")};
  padding: 0.5em;
  display: flex;
  justify-content: center;
  min-width: 50px;
  border-top: 1px solid #dadde1;
  border-right: 1px solid #dadde1;
  border-left: ${(props) => (props.left ? "1px solid #dadde1" : "")};
  text-decoration: none;
  cursor: pointer;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding: 5px;
    padding-left: 5px;
    padding-right: 5px;
    border-top: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    width: ${(props) => props.width};
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;

export default function PunditEdit({ player, onChange }) {
  const [view, setView] = useState(4);

  function updatePlayer(player) {
    onChange(player);
  }

  return (
    <Grid>
      <Grid>
        <Grid>
          <Grid.Unit size={1 / 5}>
            <Input
              value={player.first_name}
              title="First Name"
              onChange={(e) => {
                let newPlayer = { ...player, first_name: e.target.value };
                newPlayer.first_name_upper = newPlayer.first_name.toUpperCase();
                updatePlayer(newPlayer);
              }}
            />
          </Grid.Unit>
          <Grid.Unit size={1 / 5}>
            <Input
              value={player.last_name}
              title="Last Name"
              onChange={(e) => {
                let newPlayer = { ...player, last_name: e.target.value };
                newPlayer.last_name_upper = newPlayer.last_name.toUpperCase();
                updatePlayer(newPlayer);
              }}
            />
          </Grid.Unit>
          <Grid.Unit size={1 / 5}>
            <Input
              value={player.first_name_upper}
              title="First Name Upper"
              onChange={(e) => {
                let newPlayer = {
                  ...player,
                  first_name_upper: e.target.value,
                };
                updatePlayer(newPlayer);
              }}
            />
          </Grid.Unit>
          <Grid.Unit size={1 / 5}>
            <Input
              value={player.last_name_upper}
              title="Last Name Upper"
              onChange={(e) => {
                let newPlayer = {
                  ...player,
                  last_name_upper: e.target.value,
                };
                updatePlayer(newPlayer);
              }}
            />
          </Grid.Unit>

          <Grid.Unit size={1 / 5}>
            <SelectUI
              value={{
                value: player?.ss_logo,
                label: player?.ss_logo ? "yes" : "no",
              }}
              options={[
                {
                  value: true,
                  label: "yes",
                },
                {
                  value: false,
                  label: "no",
                },
              ]}
              title="SS Logo"
              placeholder="SS Logo"
              onChange={(active) => {
                let newPlayer = {
                  ...player,
                  ss_logo: active,
                };

                updatePlayer({ ...newPlayer });
              }}
            />
          </Grid.Unit>
        </Grid>
      </Grid>
      <Grid style={{ width: "100%" }}>
        <Grid.Unit size={1 / 1}>
          <Stories
            player={JSON.parse(JSON.stringify(player))}
            onUpdate={(newPlayer) => {
              updatePlayer(newPlayer);
            }}
          />
        </Grid.Unit>
      </Grid>
    </Grid>
  );
}
