import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import Grid from "styled-components-grid";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import { Button } from "../../Theme/Hyper";
import CornerBoxEditor from "./CornerBoxEditor";
import { DataContext } from "../../contexts/DataContext";
const Main = styled.div`
  display: flex;
  flex-direction: column;
`;
const Tabs = styled.div`
  display: flex;
`;

const Tab = styled.div`
  background-color: ${(props) => (props.selected ? "#fff" : "#f2f2f2")};
  color: ${(props) => (props.selected ? "#606770" : "#999da0")};
  padding: 0.5em;
  display: flex;
  justify-content: center;
  min-width: 50px;
  border-top: 1px solid #dadde1;
  border-right: 1px solid #dadde1;
  border-left: ${(props) => (props.left ? "1px solid #dadde1" : "")};
  text-decoration: none;
  cursor: pointer;
`;
const Content = styled.div`
  width: 100%;
  height: 100%;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;
function Cornerboxes({ fixture }) {
  const [view, setView] = useState("game");
  const [cornerBox, setCornerBox] = useState();
  const [cornerBoxes, setCornerBoxes] = useState([]);
  const { getCornerBoxes, deleteCornerBox } = useContext(DataContext);
  useEffect(() => {
    getData();
  }, []);
  function getData() {
    getCornerBoxes().then((data) => {
      setCornerBoxes(data);
    });
  }
  return (
    <Main>
      <Tabs>
        <Tab
          selected={view === "game"}
          onClick={() => {
            setView("game");
          }}
        >
          This Game
        </Tab>
        <Tab
          selected={view === "shared"}
          onClick={() => {
            setView("shared");
          }}
        >
          Shared
        </Tab>
      </Tabs>
      <Content>
        {view === 0 && (
          <Grid>
            <Grid.Unit size={1 / 4}></Grid.Unit>
            <Grid.Unit size={1 / 4}></Grid.Unit>
            <Grid.Unit size={1 / 4}></Grid.Unit>
            <Grid.Unit size={1 / 4}></Grid.Unit>
          </Grid>
        )}
        {fixture && (
          <Panel>
            <Button
              green
              style={{ width: "100px", margin: "1em" }}
              onClick={() => {
                setCornerBox({
                  type: view,
                  rows: new Array(10)?.fill(1)?.map(() => {
                    return {};
                  }),
                  number:
                    cornerBoxes?.filter((cb) => {
                      if (
                        view === "game" &&
                        cb?.type === "game" &&
                        cb.game?._id === fixture?._id
                      ) {
                        return true;
                      }
                      if (view === "shared" && cb?.type === "shared") {
                        return true;
                      }
                      return false;
                    })?.length + 1,
                  number_rows: 5,
                  number_columns: 1,
                  show_badges: true,
                  game: fixture?._id,
                });
              }}
            >
              + Create
            </Button>
            <PanelContent>
              <Table>
                <thead>
                  <tr>
                    <TH style={{ width: "80px" }}>Order</TH>
                    <TH>Name</TH>
                  </tr>
                </thead>
                <tbody>
                  {cornerBoxes
                    ?.filter((cb) => {
                      if (
                        view === "game" &&
                        cb?.type === "game" &&
                        cb.game?._id === fixture?._id
                      ) {
                        return true;
                      }
                      if (view === "shared" && cb?.type === "shared") {
                        return true;
                      }
                      return false;
                    })
                    ?.sort((a, b) => {
                      return (a?.number ?? 999) - (b?.number ?? 999);
                    })
                    ?.map((cb) => {
                      return (
                        <tr
                          onClick={() => {
                            setCornerBox(cb);
                          }}
                        >
                          <td>{cb?.number}</td>
                          <td>{cb?.name}</td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            </PanelContent>
          </Panel>
        )}
        <CornerBoxEditor
          game={fixture}
          cornerBox={cornerBox}
          onCancel={() => setCornerBox()}
          onUpdate={(box) => {
            setCornerBox(box);
          }}
          onSave={() => {
            setCornerBox();
            getData();
          }}
          onDelete={(box) => {
            setCornerBox();
            getData();
          }}
        ></CornerBoxEditor>
      </Content>
    </Main>
  );
}

export default Cornerboxes;
