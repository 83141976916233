import React, { useRef, useState, useContext, useEffect } from "react";
import { Button } from "../../Theme/Hyper";
import styled from "styled-components";
import HyperLogo from "../../Images/HyperLogo.png";
import { UserContext } from "../../contexts/UserContext";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { useCallback } from "react";
import MicrosoftButton from "./MicrosoftButton";

const Main = styled.div`
  display: flex;
  width: 368px;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
`;

const LogoContainer = styled.div`
  margin: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Logo = styled.img`
  height: 50px;
`;
const Form = styled.form`
  background-color: #fff;
  box-shadow: 0 1px 4px 0px rgb(0 0 0 / 16%);
  border-radius: 4px;
  padding: 1em;
  padding-left: 2em;
  padding-right: 2em;
`;
const Input = styled.input`
  display: flex;
  width: 100%;
  padding: 0.5em 0.5em;
  font-size: 0.875rem;
  line-height: 1.25;
  color: #495057;
  background-color: #fff;
  background-image: none;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5em;
  :focus {
    color: #495057;
    background-color: #fff;
    border-color: #14ec8e;
    outline: none;
  }

  :disabled {
    background-color: #d3d3d3;
    color: #999da0;
  }
`;
const Error = styled.div`
  color: #c11938;
  padding: 1em;
  display: flex;
  justify-content: center;
`;
export default function Login({ loggedIn }) {
  const { login, signinError } = useContext(UserContext);
  const usernameRef = useRef();
  const passwordRef = useRef();
  const buttonRef = useRef();
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleLogin = useCallback(async () => {
    if (!isAuthenticated && inProgress === InteractionStatus.None) {
      await instance.loginRedirect({
        scopes: ["User.Read"],
      });
    }
  }, [isAuthenticated, inProgress, instance]);

  return (
    <Main>
      <LogoContainer>
        <Logo src={HyperLogo} />
      </LogoContainer>
      {/* <h1>Welcome</h1> */}
      <Form>
        <Input
          ref={usernameRef}
          placeholder={"Username"}
          autoComplete="username"
          tabIndex="1"
        ></Input>
        <Input
          tabIndex="2"
          ref={passwordRef}
          placeholder={"Password"}
          type={"password"}
          autoComplete="current-password"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              login({
                username: usernameRef.current.value,
                password: passwordRef.current.value,
              });
            }
          }}
        ></Input>
        {signinError && <Error>{signinError}</Error>}
        <Button
          style={{ width: "100%", marginTop: "2em" }}
          red
          tabIndex="3"
          ref={buttonRef}
          onClick={() => {
            login({
              username: usernameRef.current.value,
              password: passwordRef.current.value,
            });
          }}
        >
          Login
        </Button>
        {(window.ENV?.REACT_APP_MICROSOFT_CLIENT_ID ||
          process.env.REACT_APP_MICROSOFT_CLIENT_ID) && (
          <MicrosoftButton
            onClick={() => {
              handleLogin();
            }}
          />
        )}
      </Form>
    </Main>
  );
}
