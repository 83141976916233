import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { setContext } from "@apollo/client/link/context";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  split,
} from "@apollo/client";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { createClient } from "graphql-ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { UserProvider } from "./contexts/UserContext";
import Startup from "./startup";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

let wsURI = window.ENV?.REACT_APP_RUGBY_API || process.env.REACT_APP_RUGBY_API;
wsURI = wsURI.replace("http", "ws");
wsURI = wsURI.replace("https", "wss");

const wsLink = new GraphQLWsLink(
  createClient({
    url: `${wsURI}/graphql`,
    options: {
      reconnect: true,
    },
  })
);

const httpLink = createHttpLink({
  uri:
    (window.ENV?.REACT_APP_RUGBY_API || process.env.REACT_APP_RUGBY_API) +
    "/graphql",
});

const link = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query);
    return kind === "OperationDefinition" && operation === "subscription";
  },
  wsLink,
  authLink.concat(httpLink)
);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

const msalConfig = {
  auth: {
    clientId:
      window.ENV?.REACT_APP_MICROSOFT_CLIENT_ID ||
      process.env.REACT_APP_MICROSOFT_CLIENT_ID,
    authority:
      window.ENV?.REACT_APP_MICROSOFT_AUTHORITY ||
      process.env.REACT_APP_MICROSOFT_AUTHORITY,
    redirectUri: "/",
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPII) => {
        console.log(message);
      },
      logLevel: "verbose",
    },
  },
};
let pca = null;
if (
  window.ENV?.REACT_APP_MICROSOFT_CLIENT_ID ||
  process.env.REACT_APP_MICROSOFT_CLIENT_ID
) {
  pca = new PublicClientApplication(msalConfig);

  pca.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      pca.setActiveAccount(event.payload.account);
    }
  });
  // Handle the redirect flows
  pca
    .handleRedirectPromise()
    .then((tokenResponse) => {
      // Handle redirect response
    })
    .catch((error) => {
      // Handle redirect error
    });
}

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <Startup msalInstance={pca} />
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
