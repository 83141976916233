import React, { useState, useContext, useEffect } from "react";
import Grid from "styled-components-grid";
import Input from "../UI/Form/Input";
import Headshot from "../UI/Profile/Headshot";
import moment from "moment";
import PlayerSeasonStats from "./PlayerSeasonStats";
import PlayerGameStats from "./PlayerGameStats";
import styled from "styled-components";
import Stories from "./Stories";
import SelectUI from "../UI/Form/SelectUI";
import { DataContext } from "../../contexts/DataContext";
import { AiOutlineCloudUpload } from "react-icons/ai";

const Swatch = styled.div`
  width: 50px;
  height: 30px;
  border: 1px solid grey;
  border-radius: 3px;
  margin: 0.5em;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
`;

const ColourContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;
const Cover = styled.div`
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
`;
const StatsContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

const Tabs = styled.div`
  display: flex;
`;

const Tab = styled.div`
  background-color: ${(props) => (props.selected ? "#fff" : "#f2f2f2")};
  color: ${(props) => (props.selected ? "#606770" : "#999da0")};
  padding: 0.5em;
  display: flex;
  justify-content: center;
  min-width: 50px;
  border-top: 1px solid #dadde1;
  border-right: 1px solid #dadde1;
  border-left: ${(props) => (props.left ? "1px solid #dadde1" : "")};
  text-decoration: none;
  cursor: pointer;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding: 5px;
    padding-left: 5px;
    padding-right: 5px;
    border-top: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    width: ${(props) => props.width};
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;

export default function PlayerEdit({ player, team, onChange }) {
  const [view, setView] = useState(4);
  const [countries, setCountries] = useState();
  const { getCountry } = useContext(DataContext);

  useEffect(() => {
    getCountry({}).then((countries) => {
      setCountries(countries);
    });
  }, []);

  function updatePlayer(player) {
    onChange(player);
  }

  function toFeet(n) {
    var realFeet = (n * 0.3937) / 12;
    var feet = Math.floor(realFeet);
    var inches = Math.round((realFeet - feet) * 12);
    return feet + "'" + inches + "''";
  }

  if (!player.head_shots) {
    player.head_shots = new Array(4).fill(1);
    player.head_shots = player.head_shots.map((item) => {
      return {
        url:
          "/" +
          ((team && team.name) || player.team.name) +
          "/Home/Standard/1.png",
      };
    });
  }

  return (
    <Grid>
      <Grid>
        {/* <Grid.Unit size={1 / 8}>
          <Headshot
            image={
              "___MEDIA/FOOTBALL/PLAYER_IMAGES/" +
              team?.variables?.team_photos_folder +
              "/HOME" +
              player.head_shots.find((p) => p.type === "standard")?.url
            }
            style={{ width: "120px", height: "120px" }}
          />
        </Grid.Unit> */}
        <Grid.Unit size={7 / 8}>
          {(player?.type?.toLowerCase() === "manager" ||
            player?.type?.toLowerCase() === "coach") && (
            <Grid>
              <Grid.Unit size={1 / 6}>
                <Input
                  value={player?.player?.title}
                  title="Title"
                  onChange={(e) => {
                    let newPlayer = { ...player.player, title: e.target.value };

                    updatePlayer({ ...player, player: newPlayer });
                  }}
                />
              </Grid.Unit>
            </Grid>
          )}
          <Grid>
            <Grid>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={player.player?.first_name}
                  title="First Name"
                  onChange={(e) => {
                    let newPlayer = {
                      ...player.player,
                      first_name: e.target.value,
                    };
                    newPlayer.first_name_upper =
                      newPlayer.first_name.toUpperCase();
                    updatePlayer({ ...player, player: newPlayer });
                  }}
                />
              </Grid.Unit>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={player.player?.last_name}
                  title="Last Name"
                  onChange={(e) => {
                    let newPlayer = {
                      ...player.player,
                      last_name: e.target.value,
                    };
                    newPlayer.last_name_upper =
                      newPlayer.last_name.toUpperCase();
                    updatePlayer({ ...player, player: newPlayer });
                  }}
                />
              </Grid.Unit>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={player.player?.first_name_upper}
                  title="First Name Upper"
                  onChange={(e) => {
                    let newPlayer = {
                      ...player.player,
                      first_name_upper: e.target.value,
                    };

                    updatePlayer({ ...player, player: newPlayer });
                  }}
                />
              </Grid.Unit>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={player.player?.last_name_upper}
                  title="Last Name Upper"
                  onChange={(e) => {
                    let newPlayer = {
                      ...player.player,
                      last_name_upper: e.target.value,
                    };

                    updatePlayer({ ...player, player: newPlayer });
                  }}
                />
              </Grid.Unit>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={player.player?.short_name || player.player?.last_name}
                  title="Short Name"
                  onChange={(e) => {
                    let newPlayer = {
                      ...player.player,
                      short_name: e.target.value,
                    };

                    updatePlayer({ ...player, player: newPlayer });
                  }}
                />
              </Grid.Unit>
              <Grid.Unit size={1 / 4}>
                <Input
                  value={
                    player.player?.short_name_upper ||
                    player.player?.last_name_upper
                  }
                  title="Short Name Upper"
                  onChange={(e) => {
                    let newPlayer = {
                      ...player.player,
                      short_name_upper: e.target.value,
                    };

                    updatePlayer({ ...player, player: newPlayer });
                  }}
                />
              </Grid.Unit>
              {player?.type?.toLowerCase() !== "manager" &&
                player?.type?.toLowerCase() !== "coach" && (
                  <>
                    <Grid.Unit size={1 / 4}>
                      <Input
                        value={player.shirt_number}
                        title="Shirt Number"
                        onChange={(e) => {
                          let newPlayer = {
                            ...player,
                            shirt_number: e.target.value,
                          };
                          updatePlayer(newPlayer);
                        }}
                      />
                    </Grid.Unit>
                    <Grid.Unit size={1 / 4}>
                      <SelectUI
                        value={
                          (player?.position && {
                            value: player?.position,
                            label: player?.position,
                          }) ||
                          (player?.position && {
                            value: player?.opta_position,
                            label: player?.opta_position,
                          }) ||
                          null
                        }
                        options={[
                          { value: "PROP", label: "PROP" },
                          { value: "HOOKER", label: "HOOKER" },
                          { value: "HALF BACK", label: "HALF BACK" },
                          {
                            value: "FULLBACK",
                            label: "FULLBACK",
                          },
                          {
                            value: "WINGER",
                            label: "WINGER",
                          },
                          { value: "CENTRE", label: "CENTRE" },
                          { value: "FIVE-EIGTH", label: "FIVE-EIGTH" },
                          { value: "2ND ROW", label: "2ND ROW" },
                          { value: "LOCK", label: "LOCK" },
                          { value: "INTERCHANGE", label: "INTERCHANGE" },
                          { value: "RESERVE", label: "RESERVE" },
                        ]}
                        title="Position"
                        placeholder="Select Position"
                        onChange={(position) => {
                          let newPlayer = { ...player, position: position };
                          updatePlayer(newPlayer);
                        }}
                      />
                    </Grid.Unit>
                    <Grid.Unit size={1 / 4}>
                      <SelectUI
                        value={{
                          value: player?.player?.nationality,
                          label: player?.player?.nationality
                            ? player?.player?.nationality?.opta_name +
                              " (" +
                              player?.player?.nationality?.opta_code +
                              ")"
                            : null,
                        }}
                        options={
                          countries &&
                          countries.map((c) => {
                            return {
                              value: c,
                              label: c.opta_name + " (" + c.opta_code + ")",
                            };
                          })
                        }
                        title="Nationality"
                        placeholder="Select Nationality"
                        onChange={(country) => {
                          let newPlayer = {
                            ...player.player,
                            nationality: country,
                          };

                          updatePlayer({ ...player, player: newPlayer });
                        }}
                      />
                    </Grid.Unit>

                    <Grid.Unit size={1 / 4}>
                      <FileUpload
                        team={team}
                        id="player"
                        label="Player image"
                        value={player?.player?.image || ""}
                        onChange={(filename) => {
                          let newPlayer = {
                            ...player.player,
                            image: filename,
                          };

                          updatePlayer({ ...player, player: newPlayer });
                        }}
                      />
                    </Grid.Unit>
                    <Grid.Unit size={1 / 4}>
                      <SelectUI
                        value={{
                          value: player?.active,
                          label: player?.active ? "yes" : "no",
                        }}
                        options={[
                          {
                            value: true,
                            label: "yes",
                          },
                          {
                            value: false,
                            label: "no",
                          },
                        ]}
                        title="Active"
                        placeholder="Active"
                        onChange={(active) => {
                          let newPlayer = {
                            ...player,
                            active: active,
                          };

                          updatePlayer({ ...newPlayer });
                        }}
                      />
                    </Grid.Unit>
                  </>
                )}
              {(player?.type?.toLowerCase() === "manager" ||
                player?.type?.toLowerCase() === "coach") && (
                <Grid.Unit size={1 / 4}>
                  <SelectUI
                    value={{
                      value: player?.player?.nationality,
                      label: player?.player?.nationality
                        ? player?.player?.nationality?.opta_name +
                          " (" +
                          player?.player?.nationality?.opta_code +
                          ")"
                        : null,
                    }}
                    options={
                      countries &&
                      countries.map((c) => {
                        return {
                          value: c,
                          label: c.opta_name + " (" + c.opta_code + ")",
                        };
                      })
                    }
                    title="Nationality"
                    placeholder="Select Nationality"
                    onChange={(country) => {
                      let newPlayer = {
                        ...player.player,
                        nationality: country,
                      };

                      updatePlayer({ ...player, player: newPlayer });
                    }}
                  />
                </Grid.Unit>
              )}
            </Grid>
          </Grid>
        </Grid.Unit>
      </Grid>
      {
        <Grid style={{ width: "100%" }}>
          <Grid.Unit size={1 / 1}>
            <Tabs>
              <Tab
                left
                selected={view === 4}
                onClick={() => {
                  setView(4);
                }}
              >
                Player Details
              </Tab>
              <Tab
                left
                selected={view === 0}
                onClick={() => {
                  setView(0);
                }}
              >
                Season Stats
              </Tab>
              {player.game_stats && (
                <Tab
                  left
                  selected={view === 3}
                  onClick={() => {
                    setView(3);
                  }}
                >
                  Game Stats
                </Tab>
              )}
              <Tab
                selected={view === 1}
                onClick={() => {
                  setView(1);
                }}
              >
                Stories
              </Tab>
              <Tab
                selected={view === 2}
                onClick={() => {
                  setView(2);
                }}
              >
                Headshots
              </Tab>
            </Tabs>
            {view === 4 && (
              <Table>
                <tbody>
                  <tr>
                    <td style={{ width: "200px" }}>OPTA ID</td>
                    <td>{player.opta_ID}</td>
                  </tr>
                  <tr>
                    <td>Age</td>
                    <td>
                      {player.date_of_birth}{" "}
                      {"(" + moment().diff(player.date_of_birth, "years") + ")"}
                    </td>
                  </tr>
                  <tr>
                    <td>Weight</td>
                    <td>
                      {player.weight &&
                        player.weight +
                          " kg  " +
                          (player.weight * 0.16 * 100) / 100 +
                          " st"}
                    </td>
                  </tr>
                  <tr>
                    <td>Height</td>
                    <td>
                      {player.height &&
                        toFeet(player.height) + " " + player.height + "cm"}
                    </td>
                  </tr>
                  <tr>
                    <td>Strong Foot</td>
                    <td>{player.foot}</td>
                  </tr>
                  <tr>
                    <td>OPTA Active</td>
                    <td>{player.opta_active ? "yes" : "no"}</td>
                  </tr>
                  <tr>
                    <td>OPTA First Name</td>
                    <td>{player.opta_first_name}</td>
                  </tr>
                  <tr>
                    <td>OPTA Last Name</td>
                    <td>{player.opta_last_name}</td>
                  </tr>
                  <tr>
                    <td>OPTA Nationality</td>
                    <td>
                      {countries?.find(
                        (c) => c._id === player?.opta_nationality
                      ) &&
                        countries?.find(
                          (c) => c._id === player?.opta_nationality
                        ).name}
                      {countries?.find(
                        (c) => c._id === player?.opta_nationality
                      ) &&
                        " (" +
                          countries?.find(
                            (c) => c._id === player?.opta_nationality
                          ).code +
                          ")"}
                    </td>
                  </tr>
                  <tr>
                    <td>OPTA Shirt Number</td>
                    <td>{player.opta_shirt_number}</td>
                  </tr>
                  <tr>
                    <td>OPTA Position</td>
                    <td>{player.opta_position}</td>
                  </tr>
                  <tr>
                    <td>OPTA Last updated</td>
                    <td>{player.opta_last_updated}</td>
                  </tr>
                  <tr>
                    <td>Last updated</td>
                    <td>{player.updatedAt}</td>
                  </tr>
                </tbody>
              </Table>
            )}
            {view === 0 && (
              <StatsContainer>
                <PlayerSeasonStats
                  player={player}
                  style={{ width: "100%" }}
                  onUpdate={(newPlayer) => {
                    updatePlayer(newPlayer);
                  }}
                />
              </StatsContainer>
            )}
            {view === 3 && (
              <StatsContainer>
                <PlayerGameStats player={player} style={{ width: "100%" }} />
              </StatsContainer>
            )}
            {view === 1 && (
              <Stories
                player={JSON.parse(JSON.stringify(player))}
                onUpdate={(newPlayer) => {
                  updatePlayer(newPlayer);
                }}
              />
            )}
            {view === 2 && (
              <Grid>
                {new Array(3).fill(1).map((i, index) => {
                  let type = "";
                  switch (index) {
                    case 0:
                      type = "standard";
                      break;
                    case 1:
                      type = "hero";
                      break;
                    case 2:
                      type = "torso";
                      break;
                    default:
                  }
                  return (
                    <Grid.Unit size={4 / 4}>
                      <Input
                        title={type}
                        value={
                          player.head_shots.find((h) => h.type === type)?.url
                        }
                        onChange={(e) => {
                          let newPlayer = { ...player };
                          let index = player.head_shots.findIndex(
                            (h) => h.type === type
                          );
                          if (index === -1) {
                            newPlayer.head_shots.push({
                              type: type,
                              url: e.target.value,
                            });
                          } else {
                            newPlayer.head_shots[index].url = e.target.value;
                          }

                          updatePlayer(newPlayer);
                        }}
                      />
                    </Grid.Unit>
                  );
                })}
              </Grid>
            )}
          </Grid.Unit>
        </Grid>
      }
    </Grid>
  );
}

function FileUpload({ team, value, onChange, label, id }) {
  const [open, setOpen] = useState(false);
  const userId = localStorage.getItem("user-id");
  const project_serevr_url =
    window.ENV?.REACT_APP_PROJECT_SERVER ||
    process.env.REACT_APP_PROJECT_SERVER;

  function handleClose() {
    setOpen(false);
  }

  function upload(e) {
    const files = Array.from(e.target.files);

    const formData = new FormData();

    files.forEach((file, i) => {
      formData.append("file", file);
      formData.append(file.name, team?.name + "/" + file.name);
    });

    fetch(`${project_serevr_url}/api/assets/images/${userId}`, {
      method: "POST",
      body: formData,
    }).then((data) => {
      onChange(
        team?.name?.replace(/ /g, "_") + "/" + files[0].name.replace(/ /g, "_")
      );
    });
  }
  return (
    <ColourContainer>
      <Input
        value={value}
        title={label}
        onChange={(e) => {
          onChange(e.currentTarget.value);
        }}
      />
      <input
        id={id}
        type="file"
        name="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={(e) => {
          upload(e);
        }}
      />
      <div>
        <Swatch
          style={{
            backgroundColor: value,
          }}
          onClick={() => document.getElementById(id).click()}
        >
          {!value && <AiOutlineCloudUpload />}
          {value && (
            <img
              style={{ objectFit: "contain", width: "50px", height: "30px" }}
              src={
                project_serevr_url +
                "/api/assets/images/" +
                userId +
                "/" +
                encodeURIComponent(value)
              }
              alt="Player"
            />
          )}
        </Swatch>
      </div>
    </ColourContainer>
  );
}
