import React, { useContext, useEffect, useState } from "react";
import { DataContext } from "../../contexts/DataContext";
import Panel from "../UI/Panel/Panel";
import PanelContent from "../UI/Panel/PanelContent";
import PanelTitle from "../UI/Panel/PanelTitle";
import styled from "styled-components";
import CompetitionEditor from "../Modals/CompetitionEditor";
import { Button } from "../../Theme/Hyper";

const Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.05);
  }

  th {
    padding: 10px;
    border-top: 1px solid #dee2e6;
  }
  td {
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    width: ${(props) => props.width};
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    border-top: 1px solid #dee2e6;
    white-space: nowrap;
    overflow: hidden;
    font-size: 0.8em;
  }
  > tbody > tr:hover {
    background-color: #14ec8e;
    color: white;
    cursor: pointer;
  }
`;

const TH = styled.th`
  border-top: 0;
  border-bottom: 0;
  font-weight: 800;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #343a40;
  text-align: left;
`;
export default function Competitions() {
  const { getCompetition } = useContext(DataContext);
  const [competitions, setCompetitions] = useState();
  const [selectedCompetition, setSelectedCompetition] = useState();

  useEffect(() => {
    getData();
  }, []);

  function getData() {
    getCompetition().then((data) => {
      setCompetitions(
        [...(data || [])].sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        })
      );
    });
  }
  return (
    <>
      <Button
        green
        style={{ margin: "1em", width: "200px" }}
        onClick={() => {
          setSelectedCompetition({});
        }}
      >
        + Create Competition
      </Button>
      <Panel style={{ marginTop: "1em" }}>
        <PanelTitle title={"Competition List"} />
        <PanelContent>
          <Table>
            <thead>
              <tr>
                <TH>ID</TH>
                <TH>OPTA ID</TH>
                <TH>Oval ID</TH>
                <TH>Country</TH>
                <TH>Name</TH>
                <TH>Badge</TH>
                <TH>Type</TH>
                <TH>Order</TH>
              </tr>
            </thead>
            <tbody>
              {competitions &&
                competitions
                  .sort((a, b) => {
                    if (a.country < b.country) {
                      return -1;
                    }
                    if (a.country > b.country) {
                      return 1;
                    }
                    return 0;
                  })
                  .map((competition) => {
                    return (
                      <tr
                        onClick={() => {
                          setSelectedCompetition(competition);
                        }}
                      >
                        <td>{competition._id}</td>
                        <td>{competition.opta_ID}</td>
                        <td>{competition.oval_ID}</td>
                        <td>{competition.country}</td>
                        <td>{competition.name}</td>
                        <td>{competition.badge}</td>
                        <td>{competition.competition_type}</td>
                        <td>{competition.order}</td>
                      </tr>
                    );
                  })}
            </tbody>
          </Table>
        </PanelContent>
      </Panel>
      {selectedCompetition && (
        <CompetitionEditor
          competition={selectedCompetition}
          selectCompetition={(s) => {
            setSelectedCompetition(s);
          }}
          onCancel={() => {
            setSelectedCompetition();
          }}
          onSave={() => {
            setSelectedCompetition();
            getData();
          }}
        />
      )}
    </>
  );
}
